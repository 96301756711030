import { Button, DatePicker, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./AddLoncaInvoice.scss";
import { useDispatch, useSelector } from "react-redux";
import { handleSubmit, handleVendorChange, useGetInvoiceById, useGetInvoiceData, useGetNoInvoiceOrderProductsByVendor, useSetSubVendors, useGetVendors, useSetVendors } from "./functions/helper";
import { fetchVendors } from "../../redux/vendorSlice";
import { createLoncaInvoice, fetchNoInvoiceOrderProductsByVendor, resetOrderProductsByVendor, updateLoncaInvoice } from "../../redux/loncaInvoiceSlice";
import InvoiceProducts from "./components/InvoiceProducts";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const AddLoncaInvoice = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('translation');

    const { id } = useParams();
    const invoiceExists = !!id;
    const submitFunction = invoiceExists ? updateLoncaInvoice : createLoncaInvoice;
    const title = invoiceExists ? t("lonca_invoices.edit_invoice") : t("lonca_invoices.add_new_invoice");

    const { vendors, status: vendorStatus } = useSelector(state => state.vendors);
    const { orderProductsByVendor, invoice, status: loncaInvoiceStatus } = useSelector(state => state.loncaInvoice);

    const [ activeVendors, setActiveVendors ] = useState([]);
    const [ activeSubVendors, setActiveSubVendors ] = useState([]);

    const [ selectedVendor, setSelectedVendor ] = useState("");
    const [ selectedSubVendor, setSelectedSubVendor ] = useState("");
    const [ invoiceNumber, setInvoiceNumber ] = useState("");
    const [ invoiceDate, setInvoiceDate ] = useState(null);

    const [ selectedProducts, setSelectedProducts ] = useState([]);
    const [ invoiceProducts, setInvoiceProducts ] = useState([]);

    const [ isSaveAvailable, setIsSaveAvailable ] = useState(true);

    useGetVendors(vendorStatus.fetchVendors, fetchVendors, dispatch);
    useSetVendors(vendorStatus.fetchVendors, vendors, setActiveVendors);
    useGetNoInvoiceOrderProductsByVendor(fetchNoInvoiceOrderProductsByVendor, selectedVendor, selectedSubVendor, dispatch);
    useSetSubVendors(vendorStatus.fetchVendors, activeVendors, selectedVendor, setActiveSubVendors);
    
    /** Edit */
    useGetInvoiceById(id, invoiceExists, dispatch);
    useGetInvoiceData(
        setSelectedVendor,
        setSelectedSubVendor,
        setInvoiceNumber,
        setInvoiceDate,
        setSelectedProducts,
        loncaInvoiceStatus.invoice,
        invoiceExists,
        invoice
    );

    useEffect(() => {
        setInvoiceProducts(orderProductsByVendor);
        if(!invoiceExists) {
            setSelectedProducts([]);
        }
    }, [selectedVendor, orderProductsByVendor]);
    
    return (
        <div className="add-lonca-invoice-main-container">
            <div className="add-lonca-invoice-header">
                <h2>{ title }</h2>

                <div className="add-lonca-invoice-button-group">
                    <Button
                        type="primary"
                        disabled={!isSaveAvailable}
                        onClick={() => handleSubmit(selectedProducts, selectedVendor, selectedSubVendor, invoiceNumber, invoiceDate, id, submitFunction, navigate, setIsSaveAvailable, invoiceExists, t)}
                    >
                        {t("buttons.Save")}
                    </Button>
                    <Button
                        type="secondary"
                        onClick={() => {
                            dispatch(resetOrderProductsByVendor());
                            navigate("../lonca-invoices");
                        }} 
                    >
                        { t("buttons.Cancel") }
                    </Button>
                </div>
            </div>

            <div className="add-lonca-invoice-input-container">
                <Form.Item className="add-lonca-invoice-form-item" label={t("home.vendor")}>
                    <Select 
                        placeholder={t("lonca_invoices.select_vendor")} 
                        onChange={(e) => handleVendorChange(e, setSelectedVendor)}
                        showSearch
                        disabled={invoiceExists}
                        value={selectedVendor === "" ? undefined : selectedVendor}
                    >
                        {
                            activeVendors?.map((vendor) => {
                                return (
                                    <Option key={vendor?._id} value={vendor?.name}>
                                        {vendor?.name}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </Form.Item>
                {activeSubVendors?.length > 0 && <Form.Item className="add-lonca-invoice-form-item" label={t("home.Sub Vendor")}>
                    <Select 
                        placeholder={t("lonca_invoices.select_sub_vendor")} 
                        onChange={(e) => handleVendorChange(e, setSelectedSubVendor)}
                        showSearch
                        disabled={invoiceExists}
                    >
                        {
                            activeSubVendors?.map((vendor) => {
                                return (
                                    <Option key={vendor} value={vendor}>    
                                        {vendor}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </Form.Item>}
                <Form.Item className="add-lonca-invoice-form-item" label={t("lonca_invoices.invoice_number")}>
                    <Input
                        placeholder={t("lonca_invoices.enter_invoice_number")}
                        value={invoiceNumber}
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                    />
                </Form.Item>
                <Form.Item className="add-lonca-invoice-form-item" label={t("lonca_invoices.invoice_date")}>
                    <DatePicker
                        placeholder={t("lonca_invoices.enter_invoice_date")}
                        value={invoiceDate}
                        onChange={setInvoiceDate}
                    />
                </Form.Item>
            </div>
            
            <div className="add-lonca-invoice-products-container">
                {/** Invoiced Products */}
                <InvoiceProducts 
                    products={selectedProducts} 
                    type={"invoice"} 
                    addFunction={setInvoiceProducts} 
                    removeFunction={setSelectedProducts}
                    title={t("lonca_invoices.invoice_products")}
                    isEmpty={!selectedProducts || selectedProducts?.length === 0}
                    emptyText={t("lonca_invoices.invoice_products_empty_text")}
                />

                {/** No Invoice Products */}
                <InvoiceProducts 
                    products={invoiceProducts} 
                    type={"no_invoice"} 
                    addFunction={setSelectedProducts} 
                    removeFunction={setInvoiceProducts}
                    title={t("lonca_invoices.no_invoice_products")}
                    isEmpty={!invoiceProducts || invoiceProducts?.length === 0}
                    emptyText={t("lonca_invoices.no_invoice_products_empty_text")}
                />
            </div>
        </div>
    );
}

export default AddLoncaInvoice;