import React, { useState, useEffect, useRef } from "react"
import { Input, Select, Spin } from 'antd';
import { Pagination } from '../../components';
import axios from "axios";
import {
  loncaRound,
  loncaFormatDate,
} from '../../helpers';
import {
  ALL_LOGISTICS_ORDER_STATUSES,
  ADMIN_ACTIVE_ORDER_STATUSES,
} from '../../constants'
import { fetchOrderAggregate } from '../../redux/logisticsOrderSlice';
import "./AllLogisticOrders.css";
import 'moment/locale/tr';
import { t } from "i18next";
import { DownloadOutlined } from '@ant-design/icons';


const AllLogisticOrders = () => {
  const [orders, setOrders] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState('{"createdAt":-1}');
  const [pagination, setPagination] = useState({ page: 1, limit: 100 });
  const [searchText, setSearchText] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [statusFilter, setStatusfFilter] = useState('');
  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState(ADMIN_ACTIVE_ORDER_STATUSES);

  const { Option } = Select;
  const allOrderStatusWithTranslations = ALL_LOGISTICS_ORDER_STATUSES?.map(order_status => {
    return {
      label: t(order_status.label),
      value: order_status.value
    }
  });

  const calculatePricesWithCurrency = (value, item) => {
    return !!value ? loncaRound(value * item?.rate_usd_selling) : 0;
  }

  const onOrderStatusFilterChange = (checkedValues) => {
    if(checkedValues?.includes("all")) {
        const allOrderStatuses = allOrderStatusWithTranslations?.map(option => option?.value);
        setSelectedOrderStatuses(allOrderStatuses);
        setStatusfFilter(allOrderStatuses?.filter(os => os !== "all"));
    } else if(selectedOrderStatuses?.includes("all")) {
        setSelectedOrderStatuses([]);
        setStatusfFilter([]);
    } else {
        setSelectedOrderStatuses(checkedValues);
        setStatusfFilter(checkedValues);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchText);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);
    

  useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();

    fetchOrderAggregate({
      filter: [
        statusFilter,
        searchText,
        sort,
        pagination
      ],
      mode: "all_orders",
      cancelToken: source.token
    })
      .then((res) => {
        setCartItems(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });

    return () => {
      source.cancel('Operation canceled by the user.');
    }

  }, [pagination?.page, pagination?.limit, sort, statusFilter, debouncedSearch]);

  return (
    <div>
      <div>
        <div className='top-bar'>
          <h2>All Logistic Orders</h2>
        </div>

        <div className='all-form-bar'>
          <div className="filter-container all-form-left-container">
            <span className="all-form-label">Number of orders: {cartItems?.length}</span>
            <Input className='all-w-100 all-search-bar' placeholder='Search' onChange={(e) => setSearchText(e.target.value)} />
          </div>
          <div className="all-form-right-container">
            <div className="filter-container" >
            <span className="all-form-label">Order Status</span>
              <Select
                mode="multiple"
                allowClear
                placeholder="Select Order Status"
                value={selectedOrderStatuses}
                onChange={onOrderStatusFilterChange}
                options={allOrderStatusWithTranslations}
                maxTagCount="responsive"
                showArrow
                className="all-w-100"
              />
            </div>
            <div className="filter-container">
            <span className="all-form-label">Sort</span>
              <Select placeholder='Sort' style={{ width: "90%" }} defaultValue={sort} onChange={setSort}>
                <Option value='{"createdAt":-1}'>{t('fields.edit_order.Created Date')}: {t('sort.New to Old')}</Option>
                <Option value='{"createdAt":1}'>{t('fields.edit_order.Created Date')}: {t('sort.Old to New')}</Option>
                <Option value='{"product_cost_payment_at":-1}'>{t('fields.order_status.Payment')}: {t('sort.New to Old')}</Option>
                <Option value='{"product_cost_payment_at":1}'>{t('fields.order_status.Payment')}: {t('sort.Old to New')}</Option>
                <Option value='{"delivery_date":-1}'>{t('fields.order_status.Delivery')}: {t('sort.New to Old')}</Option>
                <Option value='{"delivery_date":1}'>{t('fields.order_status.Delivery')}: {t('sort.Old to New')}</Option>
                <Option value='{"shipment_date":-1}'>{t('fields.order_status.Shipment')}: {t('sort.New to Old')}</Option>
                <Option value='{"shipment_date":1}'>{t('fields.order_status.Shipment')}: {t('sort.Old to New')}</Option>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <div className='product-table'>
        {loading && <Spin tip={"Loading Products..."} />}

        {cartItems?.map((item, index) => {

          return (
            <div className="product-container all-product-container" key={item._id + index}>
              <div className="all-item-info">
                <div className="all-info-row">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Customer</span>
                    <input className='ant-input' value={item?.customer_name} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unique Customer ID</span>
                    <Input disabled value={item?.unique_customer_id}
                    />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unique Order ID</span>
                    <Input disabled value={item?.unique_order_id}
                    />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Order Date</span>
                    <Input disabled value={loncaFormatDate(item?.createdAt)}
                    />
                  </div>
                </div>
                <div className="all-info-row">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Vendor</span>
                    <Input disabled value={item?.vendor_name} />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Product Name EN</span>
                    <Input disabled value={item?.product_name_en} />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Product Name TR</span>
                    <Input disabled value={item?.product_name_tr} />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Total Price</span>
                    <input className='ant-input' value={`$${loncaRound(item?.quantity * item?.price)} - €${calculatePricesWithCurrency(item?.quantity * item?.price, item)}`} disabled />
                  </div>
                </div>
                <div className="all-info-row">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Quantity</span>
                    <Input disabled value={item?.quantity}
                    />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unit Price</span>
                    <input className='ant-input' value={`$${loncaRound(item?.price)}`} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unit Price(₺)</span>
                    <input className='ant-input' value={`₺${loncaRound(item.price_try)}`} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Unit Price(€)</span>
                    <input className='ant-input' value={`€${loncaRound(item?.price_eur)}`} disabled />
                  </div>
                </div>
                <div className="all-info-row">
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Status</span>
                    <input className='ant-input' value={item?.status} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Product Type</span>
                    <input className='ant-input' value={item?.product_type} disabled />
                  </div>
                  <div className='all-product-info-item'>
                    <span className='product-info-label'>Notes</span>
                    <textarea className='ant-input' value={item?.note} disabled />
                  </div>
                  {item?.invoice_link && 
                  <div className='all-product-info-item'>
                  <div className="custom-file-upload" onClick={() => {window.open(item?.invoice_link)}}>
                        <DownloadOutlined/>
                          Download Invoice
                  </div>
                </div>}
                </div>
              </div>
            </div>
          )
        })}

        <Pagination filter={pagination} setFilter={setPagination} />
      </div>
    </div>
  )
}

export default AllLogisticOrders;