import React from "react";
import "./InvoiceProducts.scss";
import InvoiceProduct from "./InvoiceProduct";

const InvoiceProducts = ({
    products,
    type,
    addFunction,
    removeFunction,
    title,
    isEmpty,
    emptyText
}) => {
    const buttonName = type === "invoice" ? "buttons.Remove" : type === "no_invoice" ? "buttons.Add" : "";
    const buttonType = type === "invoice" ? "secondary" : type === "no_invoice" ? "primary" : "";

    return (
        <div className="invoice-products-main-container">
            <div className="invoice-products-header">
                <h3>{ title }</h3>
            </div>

            <div className="invoice-products-container">
                {
                    products?.map((invoiceProduct) => {
                        return (
                            <InvoiceProduct 
                                invoiceProduct={invoiceProduct} 
                                type={type} 
                                addFunction={addFunction}
                                removeFunction={removeFunction}
                                buttonName={buttonName}
                                buttonType={buttonType}
                            />
                        )
                    })
                }
                {
                    isEmpty && <strong>{ emptyText }</strong>
                }
            </div>
        </div>
    )
}

export default InvoiceProducts;