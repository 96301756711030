import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Image } from "antd";

import "./StaticContents.scss";

const StaticContents = () => {

    const navigate = useNavigate();

    return (
        <div className="static-contents-container">
            <div className="static-contents-header">
                <h2>Static Contents</h2>
            </div>

            <div className="static-content-list">
                <div className="static-content-list-item">
                    <h3>Homepage Top Categories</h3>
                    <div className="static-content-list-item-image-conainer"><Image width={240} src="/images/category_carousel.png"/></div>
                    <Button type="primary" onClick={() => navigate("/static-contents/homepage-top-categories")}>Edit</Button>
                </div>
                
                <div className="static-content-list-item">
                    <h3>Homepage Top Vendors</h3>
                    <div className="static-content-list-item-image-conainer"><Image width={240} src="/images/vendor_carousel.png"/></div>
                    <Button type="primary" onClick={() => navigate("/static-contents/homepage-top-vendors")}>Edit</Button>
                </div>

                <div className="static-content-list-item">
                    <h3>Vendors Page Sorting</h3>
                    <div className="static-content-list-item-image-conainer"><Image width={240} src="/images/vendors_page.png"/></div>
                    <Button type="primary" onClick={() => navigate("/static-contents/vendors-page-sorting")}>Edit</Button>
                </div>

                <div className="static-content-list-item">
                    <h3>Google Reviews</h3>
                    <div className="static-content-list-item-image-conainer"><Image width={240} src="/images/google_reviews.png"/></div>
                    <Button type="primary" onClick={() => navigate("/static-contents/google-reviews")}>Edit</Button>
                </div>
            </div>
        </div>
    )
};

export default StaticContents;