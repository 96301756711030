import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, notification, InputNumber, DatePicker, Modal, Spin, Checkbox, Steps } from 'antd';
import FormItem from "antd/lib/form/FormItem";
import ButtonGroup from "antd/lib/button/button-group";
import { CloseOutlined, DownloadOutlined, UserOutlined, EuroCircleOutlined, ExportOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { ResponsiveImage } from "../../components";
import moment from "moment";
import _ from 'underscore';
import "./EditOrder.css"
import {
  fetchUpdate,
  fetchCreate,
  fetchCalculatePrices,
  fetchVendorAutoOrder,
  listOrders,
  fetchOptimalShipmentChoice,
  fetchCreateEnglishInvoice,
  fetchPendingUpdate,
  createParasutInvoice,
  createShipment,
  updateOrderAddress,
  fetchCargoCosts,
  createSalesAgreement
} from "../../redux/orderSlice";
import  { fetchCountries }  from "../../redux/countrySlice";
import { fetchAllProductsHandle, fetchProductByHandleOrId } from "../../redux/productSlice";
import { fetchOrderInvoices } from "../../redux/invoiceSlice";
import  { fetchCompanies, fetchTntCargo }  from "../../redux/cargoCompanySlice";
import { fetchAllExchangeRates } from "../../redux/exchangeRatesSlice";
import {
  changeCartItem,
  calculateItemPrice,
  calculateItemPriceWithVAT,
  checkOrderStatus,
  loncaRound,
  loncaFormatDate,
  downloadCartItemImages,
  stockOutRatioExceeded
} from "../../helpers";
import { EditInvoice, MailSentStatus } from "../../components";
import { DESI_CONSTANT, ADMIN_DISABLED_ITEM_STATUSES, ADMIN_DISABLED_ORDER_STATUSES } from "../../constants";

const ObjectID = require("bson-objectid");

const EditOrder = () => {

  const { id } = useParams();
  const { t } = useTranslation('translation');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const { allSeries: seriesMain } = useSelector((state) => state.series);
  const { activeCoupons: coupons } = useSelector((state) => state.coupon); 
  const { user } = useSelector((state) => state.user);
  const { allActiveProducts: products } = useSelector((state) => state.products);
  const [initialOrder, setInitialOrder] = useState();
  const [order, setOrder] = useState();
  const [refresh, setRefresh] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [cargoCompanies, setCargoCompanies] = useState([]);

  const [saved, setSaved] = useState(true);
  const [showProductAlert, setShowProductAlert] = useState(false);
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [showDuplicateReminder, setShowDuplicateReminder] = useState(false);

  const [showInvoiceForm, setShowInvoiceForm] = useState(false);
  const [newInvoice, setNewInvoice] = useState(null);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [invoiceRefresh, setInvoiceRefresh] = useState(false);

  const [showInvoiceErrorOnTheRoad, setShowInvoiceErrorOnTheRoad] = useState(false);
  const [showBillError, setShowBillError] = useState(false);
  const [showStockError, setShowStockError] = useState(false);
  const [showBillErrorOnTheRoad, setShowBillErrorOnTheRoad] = useState(false);
  const [showInvalidReturnedUpdateError, setShowInvalidReturnedUpdateError] = useState(false);
  const [orderStatusError, setOrderStatusError] = useState(false);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [isBankOrCredit, setIsBankOrCredit] = useState(false);

  const [sortProducts, setSortProducts] = useState(null);
  const [packages, setPackages] = useState([]);
  const [calculatedDesi, setCalculatedDesi] = useState(0);
  const [calculatedWeight, setCalculatedWeight] = useState(0);
  const [reviewedCount, setReviewedCount] = useState(0)

  const [parasutInvoiceLoading, setParasutInvoiceLoading] = useState(false);
  const [orderCurrencySymbol, setOrderCurrencySymbol] = useState("$");

  const [filteredCoupons, setFilteredCoupons] = useState(coupons);
  const [isFetchingAllProducts, setIsFetchingAllProducts] = useState(false);
  const [changedCartItems, setChangedCartItems] = useState([]);
  const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [invoiceType, setInvoiceType] = useState("");
  const [shipmentCreationLoading, setShipmentCreationLoading] = useState(false);
  const [realCargoOptions, setRealCargoOptions] = useState({});
  const [loadingRealCargoCosts, setLoadingRealCargoCosts] = useState(false);
  const [loadingSalesAgreement, setLoadingSalesAgreement] = useState(false);
  const [showPaidAmountError, setShowPaidAmountError] = useState(false);
  const [isSavedForPaidAmount, setIsSavedForPaidAmount] = useState(true);
  const [needCalculatePrices, setNeedCalculatePrices] = useState(false);
  const [paymentTypeError, setPaymentTypeError] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const isLoncaCargo = order?.cargo_company?.name === "Lonca" 
  
  const [region, setRegion] = useState("");
  const renderSave = (new Date(order?.payment_at) > new Date("2022-08-20T17:00:00.000+00:00") || order?.status === "Payment Pending")

  const updatableFields = ["_id", "order_status", "quantity", "series", "notes", "notes_admin", "is_counted"];
  const conflictCheckIrrelevantFields = ["review_info", "confirm_info", "status_log" , "is_counted_log"];

  const [paymentTypes, setPaymentTypes] = useState([]);
  const { allPaymentTypes: allPaymentTypes } = useSelector((state) => state.paymentTypes);
  const { Step } = Steps;

  const [barcodeButtonLoading, setBarcodeButtonLoading] = useState(false);

  useEffect(() => {
    if (allPaymentTypes){
      for (const payment of allPaymentTypes) {
        if (payment?.name !== "Stripe" && !payment?.stripe_sub_method){
          setPaymentTypes((prev) => [...prev, payment]);
        } else if(payment?.name !== "Stripe" && payment?.stripe_sub_method){
          setPaymentTypes((prev) => [...prev, {...payment, name: "Stripe - " + payment?.name}]);
        }
      }
    }
  }, [allPaymentTypes]);

  useEffect(() => {
    let filteredCoupons = coupons.filter(coupon => coupon?.customers?.includes(order?.customer?._id))
    setFilteredCoupons(filteredCoupons)
  }, [coupons, order?.customer?._id])

  useEffect(() => {
    const country = order?.cargo_company?.name === "Own" ? "TR" : order?.address_information?.country;
    if(country) {
      fetchCompanies({ filter: {code: country} })
      .then(res => {
        setCargoCompanies(res);
      });
    }
  }, [order?.address_information?.country, order?.cargo_company?.name])

  useEffect(() => {
    if (order?.currency) fetchAllExchangeRates()
      .then(res => {
        const currency = res?.data?.find(c => (c?.abbreviation === order?.currency)) || res?.data?.find(c => (c?.abbreviation === "USD"));
        setOrderCurrencySymbol(currency?.symbol)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.currency])

  useEffect(() => {
    listOrders({
      filter: { orderId: id },
      mode: "list_one"
    })
      .then((res) => {
        const cartItem = res.data?.cart_item;
        let tempQuantity = 0;
        for (let i = 0; i < cartItem.length; i++) {
          if (cartItem[i].order_status === "Reviewed") {
            tempQuantity += cartItem[i].item_count * cartItem[i].quantity;
          }
        }
        setReviewedCount(tempQuantity);
        form.setFieldsValue({
          coupon: res.data?.coupon?.code,
          invoices: res.data?.invoices,
          status: res.data?.status,
          late_reason: res?.data?.late_reason
        });
        const mappedOrder = res.data?.cart_item?.map((item => {
          if (item.is_sample) {
            item.item_count = 1;
            item.series = "SAMPLE";
          }
          return item;
        }));

        if (res.data?.packages?.length) {
          setPackages(res.data?.packages);
          setCalculatedDesi(res.data?.packages.reduce(
            (previousValue, currentValue) => previousValue + currentValue?.desi,
            0.0
          ))
          setCalculatedWeight(res.data?.packages.reduce(
            (previousValue, currentValue) => previousValue + parseFloat(currentValue?.weight),
            0.0
          ).toFixed(2))
        }

        setInitialOrder({ ...res.data, cart_item: mappedOrder });
        setOrder({ ...res.data, cart_item: mappedOrder,
          reviewedCount: tempQuantity || 0,
          calculated_desi: res.data?.packages.reduce(
            (previousValue, currentValue) => previousValue + currentValue?.desi,
            0.0
          ) || 0, 
          calculated_weight: res.data?.packages.reduce(
            (previousValue, currentValue) => previousValue + parseFloat(currentValue?.weight),
            0.0
          ).toFixed(2) || 0,
           });
        const invalidCheckArray = ["Payment Pending", "Created"]
        const isInvalidOrder = (invalidCheckArray.includes(res.data?.status));
        const initialOrderStatuses = isInvalidOrder  ? [res.data?.status, "Received", "Not Approved"] : isLoncaCargo 
          ? ["Created", "Payment Pending", "Received", "Customer Pending", "Confirmed",  "Operations Pending", "Ready", "On the Road", "Delivery", "Completed", "Lost & Undelivered", "Returned", "Cancelled"] 
          : ["Created", "Payment Pending", "Received", "Customer Pending", "Confirmed",  "Operations Pending", "Ready", "Delivery", "Completed", "Lost & Undelivered", "Returned", "Cancelled"] ;

        setIsBankOrCredit(isInvalidOrder);
        setOrderStatuses(initialOrderStatuses);
      })
  }, [id, form, refresh, isLoncaCargo]);

  useEffect(() => {
    if (order?.customer?._id){
      fetchOrderInvoices(order?.customer?._id)
      .then((res) => {
        setInvoices(res.data);
      })
      .catch((err) => console.log(err));
    } 
  }, [order?.customer?._id, invoiceRefresh]);
 
  const compare = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  useEffect(() => {
    switch (sortProducts) {
      case 'vendor':
        setOrder(order => ({ ...order, cart_item: order?.cart_item?.sort((a, b) => compare(a.product.vendor.name, b.product.vendor.name)) }));
        break;
      case 'product-type':
        setOrder(order => ({ ...order, cart_item: order?.cart_item?.sort((a, b) => compare(a.product.product_type.names?.en, b.product.product_type.names?.en)) }));
        break;
      case 'status':
        setOrder(order => ({ ...order, cart_item: order?.cart_item?.sort((a, b) => compare(a.order_status, b.order_status)) }));
        break;
      case 'is_counted':
        setOrder(order => ({ ...order, cart_item: order?.cart_item?.sort((a, b) => (a?.is_counted === b?.is_counted) ? 0 : a?.is_counted ? -1 : 1) }));
        break;
      default:
        break;
    }
  }, [sortProducts, setSortProducts]);


  useEffect(() => {
    const order_country_code = order?.address_information?.country;
    if(order_country_code){
      fetchCountries()
      .then((res) => {
        const country = res?.filter(((country) => country?.code === order_country_code))[0]
        setRegion(country?.region)
        setCountries(res);
      })
    }
  }, [order?.address_information?.country]);

  const handlePendingUpdate = () => {
    setSaveLoading(true);
    fetchPendingUpdate(order?._id, order?.status)
      .then(() => {
        notification['success']({
          message: t('fields.edit_order.Order saved successfully'),
        });
        setSaveLoading(false)
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
        notification['error']({
          message: t("fields.edit_order.Order couldn't saved successfully"),
        });
        setSaveLoading(false)
      })
  }

  const handleSubmit = async({ order, forceUpdate = false, conflictCheckOnly = true }) => {

    setSaveLoading(true);

    let updatedOrder = {
      ...order,
      coupon: order?.coupon?._id || null,
      packages: packages || []
    }

    delete updatedOrder.cart_item;
    delete updatedOrder.address_information;
    delete updatedOrder.billing_address_information;

    const beforeUpdateItems = [];

    for (const changedItem of changedCartItems) {

      if (changedItem?.type === "add") continue;

      const isClone = changedItem?.type === "clone";
      const isDelete = changedItem?.type === "delete";

      let initialCartItem;

      if (isClone) {
        initialCartItem = initialOrder?.cart_item?.find(c => c?._id?.toString() === changedItem?.cloneReferenceItem?.toString());
      } else {
        initialCartItem = initialOrder?.cart_item?.find(c => c?._id?.toString() === changedItem?.item?._id?.toString());
      }
      
      let beforeUpdateItem = { item: { _id: changedItem?.item?._id }, type: changedItem?.type };

      for (const key of ((isClone || isDelete) ? updatableFields : Object.keys(changedItem?.item))) {
        if (!conflictCheckIrrelevantFields.includes(key)){
          beforeUpdateItem.item[key] = initialCartItem?.[key];
        }
      }

      beforeUpdateItems.push(beforeUpdateItem);
    }

    if (order?._id) {
      fetchUpdate(
        order?._id,
        updatedOrder,
        changedCartItems,
        beforeUpdateItems,
        forceUpdate, 
        conflictCheckOnly
      )
        .then((res) => {
          setSaveLoading(false);

          if (res?.conflict) {

            const conflictedCartItems = order?.cart_item?.filter(cart_item => 
              res?.nonConflictedCartItems.some(cartItem => cart_item?._id === cartItem?.item?._id)
            );
  
            let codes = "";
            
            conflictedCartItems?.map((item, index) => {
              codes += `${index + 1}-  ${item?.supplier_stock_code}<br />`;  
            })

            const message = t('fields.order_products.overwrite_notif') + "<br />" + codes || "";
            notification['error']({
              message: <div dangerouslySetInnerHTML={{ __html: message }} />,
            });
            
            handleSubmit({ order, forceUpdate: false, conflictCheckOnly: false });
            return;
          }

          setSaved(true);
          setChangedCartItems([]);
          setRefresh(r => !r);
          notification['success']({
            message: t('fields.edit_order.Order saved successfully'),
          });

          if(!isSavedForPaidAmount) {
            window.location.reload();
          }
        })
        .catch((err) => {
          setSaveLoading(false);
          setChangedCartItems([]);

          if (err?.response?.data?.error?.includes("sprinque")) {
            notification['error']({
              message: t(`errors.${err?.response?.data?.error}`),
            });
          }

          notification['error']({
            message: t("fields.edit_order.Order couldn't saved successfully"),
          });

          setRefresh(r => !r);
        });
    } else {
      updatedOrder = {
        ...updatedOrder,
        invoice: updatedOrder.invoice ? updatedOrder.invoice : [],
        cart_item: updatedOrder.cart_item ? updatedOrder.cart_item : [],
        coupon: updatedOrder.coupon ? updatedOrder.coupon : null,
      }
      fetchCreate(
        updatedOrder
      )
        .then((res) => {
          setSaveLoading(false);

          setOrder({ ...order, _id: res.data._id })
          setSaved(true);
          notification['success']({
            message: t('fields.edit_order.Order created successfully'),
          });
        })
        .catch((err) => {
          setSaveLoading(false);

          notification['error']({
            message: t("fields.edit_order.Order couldn\'t created successfully"),
          });
        });
    }
  };

  const handleCreateShipment = () => {

    const validityValue = getAddressValidityValue();

    if (!validityValue?.includes("is_valid")) {
      setShipmentCreationLoading(false);

      notification["error"]({
        message: t('fields.edit_order.nonvalid_address_error')
      });

      return;
    }

    if (order?.real_cargo_company?.name === "Fedex Standard") {
      handleTntApi();
      return;
    }

    setShipmentCreationLoading(true);

    createShipment(order?._id)
      .then((res) => {
        setShipmentCreationLoading(false);
        setRefresh(r => !r);

        if (res?.error) {
          notification["error"]({
            message: res?.error,
            duration: 7.5
          });
        }

        notification[res?.trackingNumber === "success" ? "success" : "error"]({
          message: `${order?.real_cargo_company?.name || order?.cargo_company?.name} - ` + t(`fields.edit_order.shipment_creation_${res?.trackingNumber === "success" ? "success" : "fail"}`),
          duration: 3
        });

        notification[res?.label === "success" ? "success" : "error"]({
          message: `${order?.real_cargo_company?.name || order?.cargo_company?.name} - ` + t(`fields.edit_order.shipment_label_${res?.label === "success" ? "success" : "fail"}`),
          duration: 4.5
        });

        notification[res?.englishInvoice === "success" ? "success" : "error"]({
          message: `${order?.real_cargo_company?.name || order?.cargo_company?.name} - ` + t(`fields.edit_order.shipment_english_invoice_${res?.englishInvoice === "success" ? "success" : "fail"}`),
          duration: 6
        });

        notification[res?.parasutInvoice === "success" ? "success" : "error"]({
          message: `${order?.real_cargo_company?.name || order?.cargo_company?.name} - ` + t(`fields.edit_order.shipment_parasut_invoice_${res?.parasutInvoice === "success" ? "success" : "fail"}`),
          duration: 7.5
        });
      })
      .catch((err) => {
        setShipmentCreationLoading(false);

        notification['error']({
          message: `${order?.cargo_company?.name} ${t("fields.edit_order.shipment_fail")}`,
        });
      });
  }

  const handleCreateInvoice = () => {
    setParasutInvoiceLoading(true);

    let updatedOrder = {
      ...order,
      coupon: order?.coupon || null,
      packages: packages || [],
    }

    createParasutInvoice({
      orders: [updatedOrder],
      mode: ""
    })
      .then((res) => {
        setParasutInvoiceLoading(false);
        notification['success']({
          message: t('fields.edit_order.Invoice created successfully'),
        });
      })
      .catch((err) => {
        setParasutInvoiceLoading(false);
        console.log(err);
        notification['error']({
          message: t("fields.edit_order.Invoice couldn't created successfully"),
        });
      });
  }

  const deleteInvoice = (invoice) => {
    setOrder({ ...order, invoice: order?.invoice.filter((item) => item._id !== invoice._id) });
  };

  const handleCalculations = (save = true) => {
    setSaveLoading(true);

    const isPaymentChanged = initialOrder?.payment_type !== order?.payment_type;

    fetchCalculatePrices(order, isPaymentChanged)
      .then((res) => {
        setOrder(res.data);
        notification['success']({
          message: t('fields.edit_order.Order prices calculated successfully'),
        });

        if (save){
          handleSubmit({ order:res.data, forceUpdate: false, conflictCheckOnly: true });
        }
        setSaveLoading(false);
      })
      .catch(() => {
        notification['error']({
          message: t("fields.edit_order.Order prices couldn't calculated successfully"),
        });
        setSaveLoading(false);
      })
  }

  const calculateAndSave = () => {
    if (changedCartItems?.length > 0 || needCalculatePrices){
      handleCalculations(true);
    } else {
      handleSubmit({ order, forceUpdate: false, conflictCheckOnly: true });
    }
  }

  const handleCalculationsPlusDesi = async() => {
    try {
      const isPaymentChanged = initialOrder?.payment_type !== order?.payment_type;
      const calculatedRes = await fetchCalculatePrices(order, isPaymentChanged);

      const newOrder = { ...calculatedRes.data };
      
      notification['success']({
        message: t('fields.edit_order.Order prices calculated successfully'),
      });
      
      return newOrder;
    } catch (error) {
      notification['error']({
        message: t("fields.edit_order.Order prices couldn't be calculated successfully"),
      });
    }
  }

  const handleTntApi = async () => {
    setShipmentCreationLoading(true);

    fetchTntCargo({ orders: [order?._id], mode: 'single' })
      .then(() => {
        setShipmentCreationLoading(false);
        setRefresh(r => !r);
        notification.success({
          message: t('errors.Tnt Shipment successfully'),
        });
      })
      .catch((err) => {
        setShipmentCreationLoading(false);
        notification.error({
          message: err?.response?.data?.error || t('errors.Tnt Shipment failed'),
        });
      });
  };
  

  const handleDesiChange = (change, index) => {

    let newMeasurement = {
      ...packages[index],
      ...change,
    }

    newMeasurement.desi = Math.max(
      newMeasurement.weight,
      newMeasurement.height *
      newMeasurement.length *
      newMeasurement.width /
      DESI_CONSTANT
    )

    let newPackages = packages.map((item, item_index) => {
      if (index === item_index) return newMeasurement;
      else return item;
    })

    setCalculatedDesi(newPackages.reduce(
      (previousValue, currentValue) => previousValue + currentValue?.desi,
      0.0
    ))
    setCalculatedWeight(parseFloat(newPackages.reduce(
      (previousValue, currentValue) => previousValue + parseFloat(currentValue?.weight),
      0.0
    )))

    setPackages(newPackages);
  }

  useEffect(() => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      calculated_desi: calculatedDesi,
      calculated_weight: calculatedWeight,
    }));
  }, [calculatedDesi, calculatedWeight]);

  const calculatePricesWithCurrency = (value) => {
    return !!value ? loncaRound(value * order?.rate_usd_selling) : 0;
  }
  
  const shouldBeReadyStatus = (cart_item) => {
    const isOrderStatusBeforeReady = order.status === "Created" || order.status === "Payment Pending" || order.status === "Received" || order.status === "Customer Pending" || order.status === "Confirmed" || order.status === "Operations Pending";
    const isProductStatusReady = cart_item?.every((item) => item?.order_status === "Reviewed" || item?.order_status === "StockIn");
    const isReady = isOrderStatusBeforeReady && isProductStatusReady;
    return isReady;
  }

  const shouldBeReturnedStatus = (cart_item) => {
    const isOrderStatusValidBeforeReturned = order?.status === "Delivery" || order?.status === "Completed";
    const allReturnedOrStockout = cart_item?.every((item) => item?.order_status === "Returned" || item?.order_status === "StockOut") && cart_item?.some((item) => item?.order_status === "Returned");
    const isReturned = isOrderStatusValidBeforeReturned && allReturnedOrStockout;
    return isReturned;
  }
    

  const handleItemChange = ({ item, field, value }) => {
    let newOrder = { ...order };
    // apply updates and set orders
    const result = changeCartItem({
      item: order,
      setItem: (o) => { newOrder = o },
      field,
      value,
      currentItem: item,
      series: seriesMain,
      isOrderStatus: false,
      initialItem: initialOrder?.cart_item?.find(c => c?._id?.toString() === item?._id?.toString()),
      setError: setShowStockError,
      owner: user?.full_name
    });

    if (result?.stockError) {
      handleItemChange({ item, field, value: result?.maxStock });
      return;
    }

    const updatedItem = newOrder?.cart_item?.find(i => i?._id?.toString() === item?._id?.toString());

    const updateObj = {
      _id: item?._id,
      [field]: value
    }

    if (field === "series") {
      updateObj.item_count = updatedItem?.item_count;
    }

    if (field === "order_status") {
      updateObj.review_info = updatedItem?.review_info;
      updateObj.confirm_info = updatedItem?.confirm_info;
      updateObj.status_log = updatedItem?.status_log;
    }

    if (field === "is_counted") {
      updateObj.is_counted_log = updatedItem?.is_counted_log;
    }

    if (shouldBeReadyStatus(newOrder?.cart_item)) {
      newOrder = ({ ...newOrder, status: "Ready" });
    }

    if(shouldBeReturnedStatus(newOrder?.cart_item)) {
      newOrder = ({ ...newOrder, status: "Returned" });
    }

    setOrder(newOrder);
    form.setFieldsValue({
      coupon: newOrder?.coupon?.code,
      invoices: newOrder?.invoices,
      status: newOrder?.status
    });

    let relatedCartItem = changedCartItems?.find(cartItem => cartItem?.item?._id?.toString() === item?._id?.toString());

    if (relatedCartItem) {
      // if related cart item exists, find it and replace with the new version
      setChangedCartItems(changedItems => [
        ...changedItems?.filter(changedItem => changedItem?.item?._id?.toString() !== relatedCartItem?.item?._id?.toString()),
        {
          item: {
            ...relatedCartItem?.item,
            ...updateObj
          },
          type: relatedCartItem?.type === "clone" ? "clone" : "update"
        }
      ])
    } else {
      // if relatedOrder doesn't exists, add a new changed item with updated item
      setChangedCartItems(changedItems => [
        ...changedItems, 
        { 
          item: updateObj, 
          type: "update" 
        }
      ]);
    }

  }

  const handleCloneCartItem = ({ item, index }) => {
    // create a new id
    const newId = ObjectID()?.toHexString();

    // create a new cloned cart item and set
    setOrder({
      ...order,
      cart_item: (order?.cart_item.slice(0, index + 1)).concat([{ ...item, _id: newId }], order?.cart_item.slice(index + 1))
    })

    setChangedCartItems(items => [
      ...items, 
      { 
        item: { ...item, _id: newId }, 
        type: "clone", 
        cloneReferenceItem: item?._id
      }
    ]);
  }

  const handleDeleteCartItem = ({ item }) => {
    // find item of changed item for same cart_item id
    let relatedCartItem = changedCartItems?.find(cartItem => cartItem?.item?._id?.toString() === item?._id?.toString());

    // filter and set current orders
    setOrder({
      ...order,
      cart_item: order?.cart_item?.filter(i => i?._id?.toString() !== item?._id?.toString())
    });

    // if given item is cloned or new added, filtering current state is enough, no need for api request
    const notDelete = relatedCartItem ? ["clone", "add"].includes(relatedCartItem?.type) : false;

    if (relatedCartItem && relatedCartItem?.type === "add") {
      setChangedCartItems(changedCartItems?.filter(cartItem => cartItem?.item?._id?.toString() !== item?._id?.toString()))
    }

    if (!notDelete) {
      if (relatedCartItem) {
        // if related cart item exists, find it and replace with the new version
        setChangedCartItems(changedItems => [
          ...changedItems?.filter(changedItem => changedItem?.item?._id?.toString() !== relatedCartItem?.item?._id?.toString()),
          {
            item: { _id: item?._id },
            type: "delete"
          }
        ])
      } else {
        // if relatedOrder doesn't exists, add a new changed item with deleted item
        setChangedCartItems(changedItems => [
          ...changedItems, 
          { 
            item: { _id: item?._id }, 
            type: "delete"
          }
        ]);
      }
    }
  }

  const handleAddNewProduct = async (id) => {
    const res = await fetchProductByHandleOrId({ id, region, mode: 'add_to_order'});
    const product = res.data;

    if (!product.series) {
      setShowProductAlert(true);
      return;
    }

    setSaved(false);

    // create a new id
    const newId = ObjectID()?.toHexString();

    const newCartItem = {
      ...product,
      new_addition: true,
      order_addition_date: new Date(),
      _id: newId
    };

    setOrder({
      ...order,
      cart_item: [
        newCartItem,
        ...order?.cart_item,
      ]
    });

    setChangedCartItems(changedItems => [
      ...changedItems, 
      { 
        item: newCartItem, 
        type: "add"
      }
    ]);
  }

  const handleAutoSendOrderToVendors = () => {
    fetchVendorAutoOrder(order?._id)
      .then((res) => {
        notification['success']({
          message: t('fields.edit_order.Order sent to vendors successfully'),
        });
      })
      .catch(() => {
        notification['error']({
          message: t("fields.edit_order.Order couldn't sent to vendors successfully"),
        });
      })
  }

  const handleAddressSave = (type) => {
    const addressField = type === "shipping" ? "address_information" : "billing_address_information";

    let addressValues = order?.[addressField];

    /* if (type === "shipping") {
      addressValues.valid_formatted_address = [addressValues?.city, addressValues?.state_or_province, addressValues?.postal_code, addressValues?.country]?.join(", ");
    } */

    updateOrderAddress(addressValues, order?._id, type)
      .then(() => {
        notification['success']({
          message: "Address updated",
        });
      })
      .catch((err) => {
        notification['error']({
          message: `Address update error: ${JSON.stringify(err?.response?.data)}`,
        });
      })
  }

  const handleCargoCostFetch = () => {
    if(!(Object.keys(realCargoOptions)?.length > 0) && !loadingRealCargoCosts) {
      setLoadingRealCargoCosts(true);

      fetchCargoCosts(
        order?.calculated_desi,
        cargoCompanies,
        order?.address_information,
        order?.rate_usd_buying,
        order?.cargo_company
      )
        .then(res => {
          setLoadingRealCargoCosts(false);
          setRealCargoOptions(res);
        })
        .catch(err => {
          setLoadingRealCargoCosts(false);
          notification['error']({
            message: t("fields.edit_order.fetch_cargo_costs_error"),
          });
        })
    }
  }

  const hasExchange = () => {
    const hasExchangeItem = initialOrder?.cart_item?.some(item => item?.refund && item?.refund !== "");
    const hasRefundStatus = !!initialOrder?.refund_status;
    //const allowedRefund = initialOrder?.allowed_refund;
    const exchangeExists = hasExchangeItem && hasRefundStatus;
    return exchangeExists;
  }

  const handleExchangeNavigation = () => {
      const exchangeExists = hasExchange();

      if(exchangeExists) {
        navigate(`../refunds/${order?._id}`);
      } else if(order?.allowed_refund) {
        navigate(`../add-refund`, { state: { order: initialOrder } });
      }
  }

  const handleBarcode = (barcodeUrl, productId, seriesName) => {
    setBarcodeButtonLoading(true);
    try {
      saveAs(barcodeUrl, `${productId}-${seriesName}`);
      notification['success']({
        message: "Barcode downloaded successfully!",
      });
      setBarcodeButtonLoading(false);
    } catch (error) {
      notification['error']({
        message: 'Error downloading barcode!',
      });
      setBarcodeButtonLoading(false);
    }
  }

  const getAddressValidityValue = () => {
    if (order?.address_information?.is_valid_address) return "is_valid_address";
    if (order?.address_information?.is_valid_by_lonca_geomap_address) return "is_valid_by_lonca_geomap_address";
    if (order?.address_information?.is_valid_by_lonca_google_maps_address) return "is_valid_by_lonca_google_maps_address";
    if (order?.address_information?.is_valid_by_customer_support_address) return "is_valid_by_customer_support_address";
    return "";
  }

  if (!showInvoiceForm) return (
    <div className="container">

      <div className="order-details">
        <Modal 
          title={t('fields.order_products.overwrite_warning_title')}
          visible={showOverwriteWarning}
          width={800}
          onCancel={() => setShowOverwriteWarning(false)}
          footer={[
            <Button
              key="ok" 
              type="secondary"
              onClick={() => { 
                setShowOverwriteWarning(false);
                handleSubmit({ order, forceUpdate: true, conflictCheckOnly: false });
              }}
            >
              {t('fields.order_products.save_and_overwrite')}
            </Button>,
            <Button 
              key="cancel" 
              type="primary"
              onClick={() => { 
                setShowOverwriteWarning(false);
                handleSubmit({ order, forceUpdate: false, conflictCheckOnly: false });
              }}
            >
              {t('fields.order_products.save_not_overwrite')}
            </Button>
          ]}
        >
          <p>{t('fields.order_products.overwrite_warning')}</p>
        </Modal>

        <Modal
          title="Status Warning"
          visible={showBillError}
          onCancel={() => setShowBillError(false)}
          footer={[
            <Button onClick={() => setShowBillError(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.You have to provide Bill of Lading, real cargo company and at least one invoice before changing status to Delivery.")}</p>
        </Modal>

        <Modal
          title="Status Warning"
          visible={showPaidAmountError}
          onCancel={() => {
            setShowPaidAmountError(false)
            setIsSavedForPaidAmount(false)
          }}
          footer={[
            <Button onClick={() => {
              setShowPaidAmountError(false)
              setIsSavedForPaidAmount(false)
            }}>Ok</Button>
          ]}
        >
          <p>You have to provide Paid Amount before changing status to Received.</p>
        </Modal>

        <Modal
          title="Stock Error"
          visible={showStockError}
          onCancel={() => setShowStockError(false)}
          footer={[
            <Button onClick={() => setShowStockError(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.Insufficient Stock")}</p>
        </Modal>
        <Modal
          title="Status Warning"
          visible={showBillErrorOnTheRoad}
          onCancel={() => setShowBillErrorOnTheRoad(false)}
          footer={[
            <Button onClick={() => setShowBillErrorOnTheRoad(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.You have to provide TR to NL Bill of Lading, TR to NL real cargo company and shipment date before changing status to On The Road.")}</p>
        </Modal>
        <Modal
          title="Save Warning"
          visible={showDuplicateReminder}
          onCancel={() => setShowDuplicateReminder(false)}
          footer={[
            <Button onClick={() => setShowDuplicateReminder(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.This is the duplicate of the previous order. You should use save button before leaving page in order to save duplicated order.")}</p>
        </Modal>
        <Modal
          title="Invoice Warning"
          visible={invoiceModal}
          onCancel={() => setInvoiceModal(false)}
          footer={[
            <Button onClick={() => setInvoiceModal(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.You can choose newly created invoice from invoice dropdown.")}</p>
        </Modal>
        <Modal
          title="Save Warning"
          visible={showDuplicateError}
          onCancel={() => setShowDuplicateError(false)}
          footer={[
            <Button onClick={() => setShowDuplicateError(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.You have to save your changes before creating a duplicate.")}</p>
        </Modal>
        <Modal
          title="Product Error"
          visible={showProductAlert}
          onCancel={() => setShowProductAlert(false)}
          footer={[
            <Button onClick={() => setShowProductAlert(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.The product you choose has no series information, it can't be added to the cart")}</p>
        </Modal>
        <Modal
          title="Order Status Error"
          visible={orderStatusError}
          onCancel={() => setOrderStatusError(false)}
          footer={[
            <Button onClick={() => setOrderStatusError(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.There are unconfirmed items in the cart!")}</p>
        </Modal>
        <Modal
          title="Order Status Error"
          visible={showInvoiceErrorOnTheRoad}
          onCancel={() => setShowInvoiceErrorOnTheRoad(false)}
          footer={[
            <Button onClick={() => setShowInvoiceErrorOnTheRoad(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.You should upload an English Invoice first!")}</p>
        </Modal>

        <Modal
          title="Payment Type Error"
          visible={paymentTypeError}
          onCancel={() => setPaymentTypeError(false)}
          footer={[
            <Button onClick={() => setPaymentTypeError(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.You must set the payment type in order to be able to change the order status!")}</p>
        </Modal>

        <Modal
          title="Invalid Returned Update Error"
          visible={showInvalidReturnedUpdateError}
          onCancel={() => setShowInvalidReturnedUpdateError(false)}
          footer={[
            <Button onClick={() => setShowInvalidReturnedUpdateError(false)}>Ok</Button>
          ]}
        >
          <p>{t("fields.edit_order.In order to change the status to Returned, the order status must be Delivery!")}</p>
        </Modal>


        <Form
          form={form}
          className="order-form"
          onFinish={() => calculateAndSave()}
          initialValues={{
            coupon: order?.coupon?.code,
            status: order?.status,
            invoices: order?.invoice,
          }}
          layout="vertical"
        >
          <div className="header-save">
            <h2 className="order-header">{order?.customer?.firstName} {order?.customer?.lastName} <ExportOutlined onClick={() => window.open(`../customers/${order?.customer?._id}`)}/></h2>
            <div>
              {renderSave &&
                <>
                  <Button className="button-margin" type="primary" onClick={() => handleCalculations(false)}>
                    {t('fields.edit_order.Calculate Prices')}
                  </Button>
                  <Button className="button-margin" loading={saveLoading} type="primary" htmlType="submit" >
                    {t('buttons.Save')}
                  </Button>
                </>
              }
              <Button type="secondary" onClick={() => navigate('../orders')}>
                {t('fields.edit_order.Cancel')}
              </Button>
            </div>
          </div>
          <div className="order-form-top">
            <div className="order-form-left">
              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.First Name')}>
                  <Input
                    value={order?.customer?.firstName}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Last Name')}>
                  <Input
                    value={order?.customer?.lastName}
                    disabled
                  />
                </Form.Item>
              </div>

              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Customer Email')}>
                  <Input
                    value={order?.customer?.email}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Customer Phone')}>
                  <Input
                    value={order?.customer?.phone_number}
                    disabled
                  />
                </Form.Item>
              </div>

              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Unique Order ID')}>
                  <Input
                    value={order?.unique_order_id}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Checkout Date')}>
                  <Input
                    value={loncaFormatDate(order?.checkout_date)}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Payment Date')}>
                  <Input
                    value={loncaFormatDate(order?.payment_at)}
                    disabled
                  />
                </Form.Item>
              </div>
              {order?.payment_type === "Credit" ? <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Credit Payment Date')}>
                  <Input
                    value={loncaFormatDate(order?.credit_payment_date)}
                    disabled
                  />
                </Form.Item>

                <Form.Item label={t('fields.edit_order.Last Credit Payment Date')}>
                  <Input
                    value={loncaFormatDate(order?.last_credit_payment_date)}
                    disabled
                  />
                </Form.Item>
              </div>: null}
              

              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Gross Volume')}>
                  <Input
                    value={`$${loncaRound(order?.net_volume + order?.stock_out + order?.returns + (order?.vat_discount || 0))} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(loncaRound(order?.net_volume + order?.stock_out + order?.returns + (order?.vat_discount || 0)))}` : ""}`}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Local VAT Discount')}>
                  <Input
                    value={`$${loncaRound(order?.vat_discount)} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(loncaRound(order?.vat_discount || 0))}` : ""}`}
                    disabled
                  />
                </Form.Item>
              </div>

              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Returns')}>
                  <Input
                    value={`$${order?.returns} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.returns)}` : ""}`}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Stock-outs')}>
                  <Input
                    value={`$${order?.stock_out} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.stock_out)}` : ""}`}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Net Volume')}>
                  <Input
                    value={`$${order?.net_volume} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.net_volume)}` : ""}`}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Discount')}>
                  <Input
                    value={`$${order?.discount} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.discount)}` : ""}`}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Discounted Net Volume')}>
                  <Input
                    value={`$${order?.discounted_net_volume} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.discounted_net_volume)}` : ""}`}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Product')}>
                  <Input
                    value={`$${order?.product_cost} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.product_cost)}` : ""}`}
                    disabled
                    className="input-width"
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.VAT')}>
                  <Input
                    value={`$${order?.vat} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.vat)}` : ""}`}
                    disabled
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>

              <div className="inline_inputs">
              <Form.Item label={t('fields.edit_order.Local VAT') + (order?.local_vat > 0 ? ` (${order?.local_vat_rate * 100}%)`: "")}>
                  <Input
                    value={`$${order?.local_vat} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.local_vat)}` : ""}`}
                    disabled
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Shipment Cost')}>
                  <Input
                    value={`$${order?.shipment_cost} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.shipment_cost)}` : ""}`}
                    disabled
                    style={{ width: "100%" }}
                  />
                </Form.Item>

              </div>

              <div className="inline_inputs">
                              <Form.Item label={t('fields.edit_order.Customs Document')}>
                  <Input
                    value={`${order?.customs_document_name ? order?.customs_document_name + ' - ' : ''} $${order?.customs_cost || 0} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.customs_cost)}` : ""}`}
                    disabled
                    className="input-width"
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Payment Cost')}>
                  <Input
                    value={`$${order?.payment_cost} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.payment_cost)}` : ""}`}
                    disabled
                    className="input-width"
                  />
                </Form.Item>
              </div>

              <Form.Item label={t('fields.edit_order.Paid Amount')}>
                <div className="address-box">
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Paid Amount Dollars') + " ($)"}>
                      <Input
                        value={`${order?.paid_amount}`}
                        disabled={(order?.payment_type === 'Stripe' || order?.payment_type === 'Paypal')}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            paid_amount: e.target.value
                          })
                          setIsSavedForPaidAmount(false)
                        }}
                      />
                    </Form.Item>

                    {order?.currency &&

                      <Form.Item label={t('fields.edit_order.Paid Amount Local') + ` (${orderCurrencySymbol})`}>
                        <Input
                          value={`${loncaRound(order?.paid_amount * order?.rate_usd_selling)}`}
                          disabled
                        />
                      </Form.Item>
                    }
                  </div>
                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.Rate Usd Selling')}>
                      <Input
                        value={`${orderCurrencySymbol} ${order?.rate_usd_selling}`}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Rate Usd Tr Selling')}>
                      <Input
                        value={`₺ ${order?.rate_usd_tr_selling}`}
                        disabled
                      />
                    </Form.Item>
                  </div>
                  
                  <div className="inline_inputs">
                    {order?.stripe_paid_amount && <Form.Item label={"Stripe Paid Amount" + ` (${orderCurrencySymbol})`}>
                      <Input
                        value={order?.stripe_paid_amount}
                        disabled
                      />
                    </Form.Item>}

                    <Form.Item label="Amount Returned($)">
                      <Input
                        value={order?.amount_returned}
                        disabled
                      />
                    </Form.Item>
                  </div>
                  
                  <div className="inline_inputs">
                    <Form.Item label={`Amount Returned ${orderCurrencySymbol}`}>
                      <Input
                        value={loncaRound(order?.amount_returned * order?.rate_usd_selling)}
                        disabled
                      />
                    </Form.Item>
                  </div>

                  { order?.stripe_payment_intent &&
                    <div className="inline_inputs">
                      <span>
                        <a href={`https://dashboard.stripe.com/payments/${order?.stripe_payment_intent}` } target="_blank">
                          <span style={{ color: 'red' }}>
                            <EuroCircleOutlined style={{ marginRight: 4 }} />
                            See payment in Stripe!
                          </span>
                        </a>
                      </span>
                    </div>
                  }
                  { ['Bank', 'Stripe']?.includes(order?.payment_type) && !order?.stripe_payment_intent &&
                      <div className="inline_inputs">
                          
                              {initialOrder?.stripe_manual_payment_link && 
                                  <a href={order?.stripe_manual_payment_link} target="_blank">
                                      <span style={{ color: 'blue' }}>
                                          <EuroCircleOutlined style={{ marginRight: 4 }} />
                                          See Payment With Link in Stripe!
                                      </span>
                                  </a>}
                              {!initialOrder?.stripe_manual_payment_link && 
                                  <Input
                                      type="text"
                                      placeholder="Enter Stripe Payment Link"
                                      value={order?.stripe_manual_payment_link || ""}
                                      onChange={(e) => {
                                        setOrder({
                                          ...order,
                                          stripe_manual_payment_link: e.target.value
                                        })
                                      }}
                                    />}
                              </div>
                  }
                  { order?.stripe_payment_intent && order?.customer?.stripe_customer_id &&
                    <div className="inline_inputs">
                      <span>
                        <a href={`https://dashboard.stripe.com/customers/${order?.customer?.stripe_customer_id}` } target="_blank">
                          <span style={{ color: 'red' }}>
                            <UserOutlined style={{ marginRight: 4 }} />
                            See customer in Stripe!
                          </span>
                        </a>
                      </span>
                    </div>
                  }
                  

                </div> 
              </Form.Item>
              <Form.Item label={t('fields.edit_order.Warehouse Boxes')}>
              <div className="address-box" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', maxWidth: '400px' }}>
              {order?.warehouse_boxes?.map((box, index) => (
                <div style={{ border: '1px solid black', margin: '5px', padding: '10px' }} key={index}>
                  {box}
                </div>
              ))}
            </div>

              </Form.Item>
              <Form.Item label={t('fields.edit_order.Desi Info')}>
                <div className="address-box">
                  <Form.Item label={t('fields.edit_order.Total Desi')}>
                    <Input
                      value={order?.total_desi}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label={t('fields.edit_order.Measurements')}>
                    <div className="address-box">
                      {packages.map((package_item, index) => (
                        <>
                          <Form.Item label={`${t('fields.edit_order.Package')} ${index + 1}`} >
                            <div className="address-box">
                              <div className="inline-inputs">
                                <Form.Item label={t('fields.edit_order.Length')}>
                                  <Input
                                    prefix="cm"
                                    value={package_item?.length}
                                    disabled={order?.cart_item?.find(item => item?.order_status === "Reviewed" && item?.is_counted !== true)}
                                    onChange={(e) => {
                                      handleDesiChange({ length: e.target.value }, index);
                                      setSaved(false);
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item label={t('fields.edit_order.Width')}>
                                  <Input
                                    prefix="cm"
                                    value={package_item?.width}
                                    disabled={order?.cart_item?.find(item => item?.order_status === "Reviewed" && item?.is_counted !== true)}
                                    onChange={(e) => {
                                      handleDesiChange({ width: e.target.value }, index);
                                      setSaved(false);
                                    }}
                                  />
                                </Form.Item>
                              </div>
                              <div className="inline-inputs">
                                <Form.Item label={t('fields.edit_order.Height')}>
                                  <Input
                                    prefix="cm"
                                    value={package_item?.height}
                                    disabled={order?.cart_item?.find(item => item?.order_status === "Reviewed" && item?.is_counted !== true)}
                                    onChange={(e) => {
                                      handleDesiChange({ height: e.target.value }, index);
                                      setSaved(false);
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item label={t('fields.edit_order.Weight')}>
                                  <Input
                                    prefix="kg"
                                    value={package_item?.weight}
                                    disabled={order?.cart_item?.find(item => item?.order_status === "Reviewed" && item?.is_counted !== true)}
                                    onChange={(e) => {
                                      handleDesiChange({ weight: e.target.value }, index);
                                      setSaved(false);
                                    }}
                                  />
                                </Form.Item>
                              </div>
                              <Button
                                type="primary"
                                className="button-margin calculate-button"
                                disabled={order?.cart_item?.find(item => item?.order_status === "Reviewed" && item?.is_counted !== true)}
                                onClick={() => {
                                  const newPackages = packages.filter((item, inner_index) => {
                                    return inner_index !== index
                                  });
                                  setPackages(newPackages)
                                  setCalculatedDesi(newPackages.reduce((prev, curr) => prev + curr?.desi, 0.0))
                                  setCalculatedWeight(parseInt(newPackages.reduce((prev, curr) => prev + curr?.weight, 0.0)))
                                }}
                              >
                                {t('fields.edit_order.Remove')}
                              </Button>
                            </div>
                          </Form.Item>
                        </>
                      ))}

                      <Button
                        type="primary"
                        className="button-margin calculate-button"
                        disabled={order?.cart_item?.find(item => item?.order_status === "Reviewed" && item?.is_counted !== true)}
                        onClick={() => {
                          setPackages(packages => [...packages, { width: 0, height: 0, weight: 0, length: 0, desi: 0 , date: new Date()}])
                        }}
                      >
                        {t('fields.edit_order.Add Package')}
                      </Button>

                      <div className="inline_inputs_edit_order">
                        <Form.Item label={t('fields.edit_order.Calculated Desi')}>
                          <Input
                            value={calculatedDesi}
                            disabled
                          />
                        </Form.Item>

                        <Form.Item label={t('fields.edit_order.Calculated Weight')}>
                          <Input
                            value={calculatedWeight}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item label={t('fields.edit_order.Reviewed Count')}>
                          <Input
                            value={reviewedCount}
                            disabled
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item label={t('fields.edit_order.Shipping Address')}>
                <div className="address-box">
                  <div className="inline-inputs">
                    
                    <Form.Item label={t('Valid Address')}>
                      <Input
                        value={order?.address_information?.valid_formatted_address}
                        onChange={(e) => setOrder({
                          ...order,
                          address_information: {
                            ...order?.address_information,
                            valid_formatted_address: e.target.value
                          }
                        })}
                        disabled={!getAddressValidityValue()?.includes("is_valid")}
                      />
                    </Form.Item>
                    
                    <Form.Item label={t('fields.edit_order.Address')}>
                      <Input
                        value={order?.address_information?.address}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              address: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.apartment_suite')}>
                      <Input
                        value={order?.address_information?.apartment_suite}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              apartment_suite: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    
                    <Form.Item label={t('fields.edit_order.City')}>
                      <Input
                        value={order?.address_information?.city}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              city: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.State')}>
                      <Input
                        value={order?.address_information?.state_or_province}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              state_or_province: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        value={order?.address_information?.postal_code}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              postal_code: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Country')}>
                      <Select
                        showSearch
                        value={order?.address_information?.country}
                        optionFilterProp="children"
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              country: e
                            }
                          });
                          setSaved(false);
                        }}>
                        {countries?.map((country) =>
                            <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                      </Select>
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Phone Number')}>
                      <Input
                        value={order?.address_information?.phone_number}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              phone_number: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.address_validity')}>
                      <Select
                        value={getAddressValidityValue()}
                        onChange={(e) => {
                          const fields = ["is_valid_address", "is_valid_by_lonca_geomap_address", "is_valid_by_lonca_google_maps_address", "is_valid_by_customer_support_address"];
                          const changedField = e;
                          const otherFields = fields?.filter(f => f !== changedField);
                          const otherFieldsMapping = otherFields?.reduce((acc, crr) => {
                            acc[crr] = false;
                            return acc;
                          }, {})

                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              [changedField]: true,
                              ...otherFieldsMapping
                            }
                          });
                          setSaved(false);
                        }}
                      >
                        <Option value=""></Option>
                        <Option value="is_valid_address">{t('fields.edit_order.is_valid_address')}</Option>
                        <Option value="is_valid_by_lonca_geomap_address">{t('fields.edit_order.is_valid_by_lonca_geomap_address')}</Option>
                        <Option value="is_valid_by_lonca_google_maps_address">{t('fields.edit_order.is_valid_by_lonca_google_maps_address')}</Option>
                        <Option value="is_valid_by_customer_support_address">{t('fields.edit_order.is_valid_by_customer_support_address')}</Option>
                      </Select>
                    </Form.Item>
                    

                    <Button
                      type="primary"
                      onClick={() => handleAddressSave("shipping")}
                      style={{ marginTop: "1.85rem" }}
                    >
                      {t('fields.edit_order.save_shipping_address')}
                    </Button>
                  </div>

                </div>
              </Form.Item>

              <Form.Item label={t('fields.edit_order.Billing Address')}>
                <div className="address-box">
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Billing Name')}>
                      <Input
                        value={order?.billing_address_information?.billing_name}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              billing_name: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Company Type')}>
                      <Input
                        value={order?.billing_address_information?.company_type}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              company_type: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.VAT Number')}>
                      <Input
                        value={order?.billing_address_information?.corporate_identity_number}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              corporate_identity_number: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Tax Office')}>
                      <Input
                        value={order?.billing_address_information?.tax_office}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              tax_office: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Address')}>
                      <Input
                        value={order?.billing_address_information?.address}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              address: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.City')}>
                      <Input
                        value={order?.billing_address_information?.city}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              city: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        value={order?.billing_address_information?.postal_code}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              postal_code: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Country')}>
                      <Select
                        showSearch
                        value={order?.billing_address_information?.country}
                        optionFilterProp="children"
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              country: e
                            }
                          });
                          setSaved(false);
                        }}>
                        {countries?.map((country) =>
                            <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    <div className="checkbox-container">
                      <label>
                      <input
                          type="checkbox"
                          checked={order?.billing_address_information?.isCredit}
                          onChange={(e) => {
                            setOrder({
                              ...order,
                              billing_address_information: {
                                ...order?.billing_address_information,
                                isCredit: order?.billing_address_information?.isCredit
                              }
                            })
                          }}
                          disabled={true}
                      />
                        isCredit
                      </label>
                  </div>

                    <Button
                      type="primary"
                      onClick={() => handleAddressSave("billing")}
                    >
                      {t('fields.edit_order.save_billing_address')}
                    </Button>
                  </div>
                </div>
              </Form.Item>
              {order?.cargo_company?.name === "Own" &&  !order?.own_cargo_carrier_info?.own_cargo_address &&
              <Form.Item label={t('fields.edit_order.Own Cargo Company Info')}>
                <div className="address-box">
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Carrier Name')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.carrier_name}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              carrier_name: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Carrier Phone')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.carrier_phone}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              carrier_phone: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item label={t('fields.edit_order.Carrier Address')}>
                    <Input
                      value={order?.own_cargo_carrier_info?.carrier_address}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          own_cargo_carrier_info: {
                            ...order?.own_cargo_carrier_info,
                            carrier_address: e.target.value
                          }
                        });
                        setSaved(false);
                      }}
                    />
                  </Form.Item>
                  
                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.Carrier VAT Number')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.carrier_vat_number}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              carrier_vat_number: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Unique Cargo Code')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.unique_cargo_code}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              unique_cargo_code: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item label={t('fields.edit_order.Cargo Instructions')}>
                    <Input
                      value={order?.own_cargo_carrier_info?.cargo_instructions}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          own_cargo_carrier_info: {
                            ...order?.own_cargo_carrier_info,
                            cargo_instructions: e.target.value
                          }
                        });
                        setSaved(false);
                      }}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              }
              
              {order?.cargo_company?.name === "Own" &&  order?.own_cargo_carrier_info?.own_cargo_address &&
              <Form.Item label={t('fields.edit_order.Own Cargo Company Address Info')}>
                <div className="address-box">
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Cargo Company Name')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.cargo_company_name}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              cargo_company_name: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Phone')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.own_cargo_address?.phone_number}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              own_cargo_address: {
                                ...order?.own_cargo_carrier_info?.own_cargo_address,
                              phone_number: e.target.value
                              }
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="inline_inputs">
                  <Form.Item label={t('fields.edit_order.Address City')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.own_cargo_address?.city}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              own_cargo_address: {
                                ...order?.own_cargo_carrier_info?.own_cargo_address,
                              city: e.target.value
                            }
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    
                  <Form.Item label={t('fields.edit_order.Address Country')}>
                    <Select
                      showSearch
                      value={order?.own_cargo_carrier_info?.own_cargo_address?.country}
                      optionFilterProp="children"
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          own_cargo_carrier_info: {
                            ...order?.own_cargo_carrier_info,
                            own_cargo_address: {
                              ...order?.own_cargo_carrier_info?.own_cargo_address,
                            country: e
                            }
                          }
                        });
                        setSaved(false);
                      }}>
                      {countries?.map((country) =>
                          <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                    </Select>
                  </Form.Item>
                  
                  </div>
                  <Form.Item label={t('fields.edit_order.Address')}>
                    <Input
                      value={order?.own_cargo_carrier_info?.own_cargo_address?.address}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          own_cargo_carrier_info: {
                            ...order?.own_cargo_carrier_info,
                            own_cargo_address: {
                              ...order?.own_cargo_carrier_info?.own_cargo_address,
                            address: e.target.value
                            }
                          }
                        });
                        setSaved(false);
                      }}
                    />
                  </Form.Item>
                  
                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.own_cargo_address?.postal_code}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              own_cargo_address: {
                                ...order?.own_cargo_carrier_info?.own_cargo_address,
                              postal_code: e.target.value
                            }}
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Unique Cargo Code')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.unique_cargo_code}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              unique_cargo_code: e.target.value
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>

                  <Form.Item label={t('fields.edit_order.Cargo Instructions')}>
                    <Input
                      value={order?.own_cargo_carrier_info?.cargo_instructions}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          own_cargo_carrier_info: {
                            ...order?.own_cargo_carrier_info,
                            cargo_instructions: e.target.value
                          }
                        });
                        setSaved(false);
                      }}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
              }
              {order?.cargo_company?.name === "Own" &&  order?.own_cargo_carrier_info?.own_cargo_address &&
              <Form.Item label={t('fields.edit_order.Own Cargo Company Billing Address Info')}>
                <div className="address-box">
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Billing Name')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.own_cargo_billing_address?.billing_name}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              own_cargo_billing_address: {
                                ...order?.own_cargo_carrier_info?.own_cargo_billing_address,
                              billing_name: e.target.value
                              }
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Corporate Identity Number')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.own_cargo_billing_address?.corporate_identity_number}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              own_cargo_billing_address: {
                                ...order?.own_cargo_carrier_info?.own_cargo_billing_address,
                                corporate_identity_number: e.target.value
                              }
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="inline_inputs">
                  <Form.Item label={t('fields.edit_order.Billing City')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.own_cargo_billing_address?.city}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              own_cargo_billing_address: {
                                ...order?.own_cargo_carrier_info?.own_cargo_billing_address,
                              city: e.target.value
                            }
                            }
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>

                  <Form.Item label={t('fields.edit_order.Billing Country')}>
                    <Select
                      showSearch
                      value={order?.own_cargo_carrier_info?.own_cargo_billing_address?.country}
                      optionFilterProp="children"
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          own_cargo_carrier_info: {
                            ...order?.own_cargo_carrier_info,
                            own_cargo_billing_address: {
                              ...order?.own_cargo_carrier_info?.own_cargo_billing_address,
                            country: e
                            }
                          }
                        });
                        setSaved(false);
                      }}>
                      {countries?.map((country) =>
                          <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                    </Select>
                  </Form.Item>
                  
                  </div>
                  <Form.Item label={t('fields.edit_order.Billing Address')}>
                    <Input
                      value={order?.own_cargo_carrier_info?.own_cargo_billing_address?.address}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          own_cargo_carrier_info: {
                            ...order?.own_cargo_carrier_info,
                            own_cargo_billing_address: {
                              ...order?.own_cargo_carrier_info?.own_cargo_billing_address,
                            address: e.target.value
                            }
                          }
                        });
                        setSaved(false);
                      }}
                    />
                  </Form.Item>
                  
                  <div className="inline_inputs">
                  <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        value={order?.own_cargo_carrier_info?.own_cargo_address?.postal_code}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            own_cargo_carrier_info: {
                              ...order?.own_cargo_carrier_info,
                              own_cargo_address: {
                                ...order?.own_cargo_carrier_info?.own_cargo_address,
                              postal_code: e.target.value
                            }}
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  <Form.Item label={t('fields.edit_order.Tax Office')}>
                    <Input
                      value={order?.own_cargo_carrier_info?.own_cargo_billing_address?.tax_office}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          own_cargo_carrier_info: {
                            ...order?.own_cargo_carrier_info,
                            own_cargo_billing_address: {
                              ...order?.own_cargo_carrier_info?.own_cargo_billing_address,
                            tax_office: e.target.value
                            }
                          }
                        });
                        setSaved(false);
                      }}
                    />
                  </Form.Item>
                  </div>
                </div>
              </Form.Item>
              }
              
              <Form.Item label={t('fields.edit_order.Delivery')}>
                <div className="address-box">

                  <div className="inline-inputs">
                    <Form.Item 
                      label={t('fields.edit_order.Real Cargo Company')}
                      onClick={handleCargoCostFetch}
                    >
                      <Select
                        disabled={!(Object.keys(realCargoOptions)?.length > 0) && !loadingRealCargoCosts}
                        placeholder={
                          !(Object.keys(realCargoOptions)?.length > 0) 
                            ? t('fields.edit_order.fetch_cargo_costs')
                            : ""
                        }
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            real_cargo_company: e ? cargoCompanies.find(c => c?._id === e) : null,
                            real_cargo_cost: realCargoOptions[cargoCompanies?.find(c => c?._id === e)?.name] || 0
                          });
                          setSaved(false);
                        }}
                        value={order?.real_cargo_company?._id}
                      >
                        <Option value={null}>{t('fields.edit_order.None')}</Option>

                        { 
                          cargoCompanies
                            ?.filter(company => {
                              if (isLoncaCargo) {
                                return !!company?.first_mile;
                              } else {
                                return !company?.first_mile && !company?.last_mile;
                              }
                            })
                            ?.map((company, index) => {
                              return (
                                <Option key={'type-select' + index} value={company._id}>
                                  {company?.name === "Own" ? t(`fields.edit_order.${company.name}`) : company.name}
                                  { 
                                    Object.keys(realCargoOptions)?.length > 0 
                                      ? realCargoOptions[company.name] 
                                        ? ` - $${realCargoOptions[company.name]}` 
                                        : " - $0" 
                                      : ` - ${t('fields.edit_order.fetch_cargo_costs')}`
                                  }
                                </Option>
                              )
                            }) 
                        }
                      </Select>

                    </Form.Item>

                    <Form.Item 
                      label={(isLoncaCargo || order?.real_cargo_company?.name === "Lonca")
                        ? t('fields.edit_order.Bill of Lading TR to NL') 
                        : t('fields.edit_order.Bill of Lading')}
                    >
                      <Input
                        value={order?.bill_of_lading}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            bill_of_lading: e.target.value
                          });
                          setSaved(false);
                        }}
                      />
                    </Form.Item>

                    { isLoncaCargo &&
                      <Form.Item 
                        label={t('fields.edit_order.Real Cargo Company - NL')}
                        onClick={handleCargoCostFetch}
                      >
                        <Select
                          disabled={(!(Object.keys(realCargoOptions)?.length > 0) && !loadingRealCargoCosts) || !initialOrder?.bill_of_lading}
                          placeholder={
                            !(Object.keys(realCargoOptions)?.length > 0) 
                              ? t('fields.edit_order.fetch_cargo_costs')
                              : ""
                          }
                          onChange={(e) => {
                            setOrder({
                              ...order,
                              real_cargo_company_nl: e ? cargoCompanies.find(c => c?._id === e) : null
                            });
                            setSaved(false);
                          }}
                          value={order?.real_cargo_company_nl?._id}
                        >
                          <Option value={null}>{t('fields.edit_order.None')}</Option>

                          {order?.real_cargo_company_nl?._id && !cargoCompanies?.some(company => company?.id === order?.real_cargo_company_nl?._id) ? 
                                      <Option value={order?.real_cargo_company_nl?._id}>{order?.real_cargo_company_nl?.name}</Option>
                          : null}
                          
                          { 
                            cargoCompanies
                              ?.filter(company => !!company?.last_mile)
                              ?.map((company, index) => {
                                return (
                                  <Option key={'type-select' + index} value={company._id}>
                                    { company?.name === "Own" ? t(`fields.edit_order.${company.name}`) : company.name }
                                    { 
                                      Object.keys(realCargoOptions)?.length > 0 
                                        ? realCargoOptions[company.name] 
                                          ? ` - $${realCargoOptions[company.name]}` 
                                          : " - $0" 
                                        : ` - ${t('fields.edit_order.fetch_cargo_costs')}`
                                    }
                                  </Option>
                                )
                              })
                          }
                          
                        </Select>
                      </Form.Item>
                    }

                    { isLoncaCargo &&
                      <Form.Item label={t('fields.edit_order.Bill of Lading NL to Customer')}>
                        <Input
                          disabled={!initialOrder?.bill_of_lading ? true : false}
                          value={order?.bill_of_lading_nl}
                          onChange={(e) => {
                            setOrder({
                              ...order,
                              bill_of_lading_nl: e.target.value
                            });
                            setSaved(false);
                          }}
                        />
                      </Form.Item>
                    }
                  </div>

                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Shipment Date')}>
                      <DatePicker
                        placeholder={t('fields.edit_order.Select Date')}
                        showTime
                        value={order?.shipment_date ? moment(order?.shipment_date) : null}
                        onChange={(e) => {
                          setOrder({ ...order, shipment_date: new Date(e._d) })
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Delivery Date')}>
                      <DatePicker
                        placeholder={t('fields.edit_order.Select Date')}
                        showTime
                        value={order?.delivery_date ? moment(order?.delivery_date) : null}
                        onChange={(e) => {
                          setOrder({ ...order, delivery_date: new Date(e._d) })
                          setSaved(false);
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>
              <Form.Item label={t('fields.edit_order.Scan Logs')}>
                <div className="address-box">
                    <Steps current={order?.scan_logs?.date_in_process_date ? order?.scan_logs?.created_label_date ? (order?.scan_logs?.pickup_scan_date ? 4 : 1.5) : 0.5  : -1} size="small" >
                          <Step title="Entered Scan" description={loncaFormatDate(order?.scan_logs?.date_in_process_date, true)} />
                          <Step title="Label Created" description={loncaFormatDate(order?.scan_logs?.created_label_date, true)} />
                          <Step title="Left Scan" description={loncaFormatDate(order?.scan_logs?.pickup_scan_date, true)} />
                    </Steps>    
                </div>
              </Form.Item>
            </div>
            <div className="order-form-right">
              <div className="status-save">

                <Form.Item name="status" label={t('fields.edit_order.Status')}>
                  <Select
                    value={order?.status}
                    disabled={order?.status === 'Returned'}
                    onChange={async (e) => {
                      let orderObject = order;
                      if ((!order?.bill_of_lading || !order?.invoice?.length || !order?.real_cargo_company) && e === "Delivery" && !order?.cargo_company?.delivery_without_tracking_code) {
                        setShowBillError(true);
                        form.setFieldsValue({ status: order?.status })
                        return;
                      }

                      if ((!order?.paid_amount || order?.paid_amount === 0) && e === "Received" && order?.status === "Payment Pending") {
                        setShowPaidAmountError(true);
                        form.setFieldsValue({ status: order?.status })
                        return;
                      }

                      if ((!order?.bill_of_lading || !order?.shipment_date || !order?.real_cargo_company) && e === "On the Road" && !order?.cargo_company?.delivery_without_tracking_code) {
                        setShowBillErrorOnTheRoad(true);
                        form.setFieldsValue({ status: order?.status })
                        return;
                      }

                      if (e === "On the Road") {
                        const englishInvoice = order?.invoice?.find(i => i?.type !== "parasut");
                        if (!englishInvoice) {
                          setShowInvoiceErrorOnTheRoad(true);
                          form.setFieldsValue({ status: order?.status })
                          return;
                        }
                      }

                      if(!order?.payment_type && e === "Received") {  // ! debug !
                          setPaymentTypeError(true);
                          form.setFieldsValue({ status: order?.status });
                          return;
                      }
                      if (order?.status !== 'Delivery' && e === 'Returned') {
                        setShowInvalidReturnedUpdateError(true);
                        form.setFieldsValue({ status: order?.status });
                        return;
                      }
                      if (order?.status !== "Confirmed" && e === "Confirmed") {
                        if (!checkOrderStatus(order?.cart_item)) {
                          setOrderStatusError(true);
                          form.setFieldsValue({ status: order?.status })
                          return;
                        } 
                      } else if ( order?.status !== "Completed" && e === "Completed" ) {
                        orderObject = await handleCalculationsPlusDesi();
                      }

                      setOrder({
                        ...orderObject,
                        status: e
                      });
                      setSaved(false);
                    }}
                  >
                    {orderStatuses.map(status => (
                      <Option value={status}>{t(`fields.order_status.${status}`)}</Option>
                    ))}
                  </Select>
                </Form.Item>

                {
                  isBankOrCredit ?
                    <Button type="primary" className="status-save-button"
                      disabled={!isSavedForPaidAmount || saveLoading}
                      onClick={() => handlePendingUpdate()}
                    >
                      {t('fields.edit_order.Save Status')}
                    </Button>
                    : null
                }

              </div>

              <Form.Item name="late_reason" label={t('fields.edit_order.Late Reason')}>
                <Select
                  onChange={(e) => {
                    setOrder({
                      ...order,
                      late_reason: e
                    });
                    setSaved(false);
                  }}
                  value={order?.late_reason}
                >
                  <Option value={null}>{t('fields.edit_order.None')}</Option>
                  <Option value={"Lonca"}>{t('fields.edit_order.Lonca')}</Option>
                  <Option value={"Customer"}>{t('fields.edit_order.Customer')}</Option>

                </Select>
              </Form.Item>
              <Form.Item name="coupon" label={t('fields.edit_order.Coupon')}>
                <Select
                  onChange={(e) => {
                    setOrder({
                      ...order,
                      coupon: e ? coupons.find((i) => i._id === e) : null
                    });
                    setSaved(false);
                    setNeedCalculatePrices(true);
                  }}
                  value={order?.coupon}
                >
                  <Option value={null}>{t('fields.edit_order.None')}</Option>
                  {filteredCoupons.map((coupon, index) => (
                    <Option key={'type-select' + index} value={coupon._id}>
                      {coupon.code}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={t('fields.edit_order.Cargo Company')}>
                <Select
                  disabled
                  value={ order?.cargo_company?._id }
                >
                  <Option value={null}>{t('fields.edit_order.None')}</Option>
                  {cargoCompanies?.map((company, index) => (
                    <Option key={'type-select' + index} value={company._id}>
                      {company.name}
                    </Option>
                  ))}
                </Select>

              </Form.Item>

              <div className="payment-type">
                  <Form.Item label={t('fields.edit_order.Payment Type')} className="payment-selection">
                    <Select
                      value={
                        order?.sub_payment_type 
                          ? (order?.payment_type + ' - ' + order?.sub_payment_type) 
                            : order?.payment_type
                      }      
                      onChange={(e) => {
                        const newPaymentType = e.split(' - ')[0];
                        const newSubPaymentType = e.split(' - ')[1];
                        setOrder({
                          ...order,
                          payment_type: newPaymentType,
                          sub_payment_type: newSubPaymentType ? newSubPaymentType : null
                        });
                        setSaved(false);
                        setNeedCalculatePrices(true);
                      }}  
                    >
                      {paymentTypes?.map((payment, index) => (
                        <Option key={'type-select' + index} value={payment?.name}>
                          {payment?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                        {order?.payment_type === "PayTR" ? 
                          <div className="installment">{order?.installment_count === 1 ? t('fields.edit_order.single_payment') : `${order?.installment_count} ${t('fields.edit_order.installments')}`}
                          </div>
                        : null}
                </div>
                {(order?.cargo_company?.name === "Own") &&
                    <Form.Item label={t('fields.edit_order.Invoice To')}>
                      <Select value={order?.invoice_to || "Customer" } onChange={(e) => {
                        setOrder({
                          ...order,
                          invoice_to: e
                        });
                        setSaved(false);
                      }}>
                        <Option value="Customer">{t('fields.edit_order.Customer')}</Option>
                        <Option value="Cargo_Company">{t('fields.edit_order.Cargo Company')}</Option>
                      </Select> 
                    </Form.Item>
                  }
              <Form.Item>
                <div className="address-box">
                  <Form.Item label={t('fields.edit_order.Customer Notes')}>
                    <TextArea
                      value={order?.special_request}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          special_request: e.target.value
                        })
                      }}
                      placeholder={t('fields.edit_order.Customer Notes')}
                      disabled
                    />
                  </Form.Item>
                </div>
                <div className="address-box">
                  <Form.Item label={t('fields.edit_order.Admin Notes')}>
                    <TextArea
                      value={order?.special_request_admin}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          special_request_admin: e.target.value
                        })
                      }}
                      placeholder={t('fields.edit_order.Admin Notes')}
                    />
                  </Form.Item>
                </div>
              </Form.Item> 
                
              <Form.Item>
                <div className="address-box">
                  <Form.Item label={t('fields.edit_order.Customer Pending Final')}>
                    {order?.not_allowed_auto_refund && 
                      <div>
                        <span style={{ color: 'red' }}>
                          Auto refund is not available for this order because of below reason(s):
                        </span>
                        
                        {stockOutRatioExceeded(order?.cart_item) && 
                          <span style={{ color: 'red' }}>
                            <br></br>
                            This order exceeds stock out ratio (60%) for auto refund!
                          </span>
                         }
                        
                        {order?.discounted_net_volume > order?.paid_amount && 
                          <span style={{ color: 'red' }}>
                            <br></br>
                            Discounted value exceeds paid amount!
                          </span>
                        }
                        
                        {order?.local_vat < 0 && 
                          <span style={{ color: 'red' }}>
                            <br></br>
                            Local VAT should not be smaller than 0!
                          </span>
                        }

                        {order?.discounted_net_volume < 0 && 
                          <span style={{ color: 'red' }}>
                            <br></br>
                            Discounted Net Volume is less than 0!
                          </span>
                        }
                      </div>
                    }
 
                    <Input
                      value={
                        order?.stock_out_refund?.mode &&
                        `${t(`fields.order_status.${order?.stock_out_refund?.mode}`)}\n - Date: ${loncaFormatDate(order?.stock_out_refund?.date)}`
                      }
                      placeholder="Customer Pending..."
                      disabled
                    />
                    {order?.stock_out_refund?.note ?

                      <TextArea
                        disabled
                        value={order?.stock_out_refund?.note}
                        placeholder={t('fields.edit_order.Placeholder')}
                      />
                      : null  
                    }

                    {(order?.stock_out_refund?.amount || order?.stock_out_refund?.after_refund_dnv) ? (
                        <TextArea
                          disabled
                          rows={3}
                          value={
                            'Amount: ' +
                            orderCurrencySymbol +
                            loncaRound(loncaRound(order?.stock_out_refund?.amount) * order?.rate_usd_selling).toFixed(2) +
                            '\n' + 'DNV: ' +  // New line character
                            (order?.stock_out_refund?.after_refund_dnv
                              ? orderCurrencySymbol +
                                loncaRound(loncaRound(order?.stock_out_refund?.after_refund_dnv) * order?.rate_usd_selling).toFixed(2)
                              : '')
                              +
                              '\n' + 'Total: ' + loncaRound((loncaRound(order?.stock_out_refund?.amount) + loncaRound(order?.stock_out_refund?.after_refund_dnv)) * order?.rate_usd_selling).toFixed(2)
                          }
                          placeholder={t('fields.edit_order.Placeholder')}
                        />
                      ) : null}
                    
                  </Form.Item>

                  {order?.stock_out_refund_logs?.length > 1 &&
                    <Form.Item label={t('fields.edit_order.Customer Pending Logs')}>
                      {order?.stock_out_refund_logs?.map((refund) => (
                        <div>
                          <TextArea
                            disabled
                            rows={3}
                            value={
                              `Date: ${loncaFormatDate(refund?.date)}` + '\n' +
                              'Amount: ' +
                              orderCurrencySymbol +
                              loncaRound(loncaRound(refund?.amount) * order?.rate_usd_selling).toFixed(2) +
                              '\n' + 'DNV: ' +  // New line character
                              (refund?.after_refund_dnv
                                ? orderCurrencySymbol +
                                  loncaRound(loncaRound(refund?.after_refund_dnv) * order?.rate_usd_selling).toFixed(2)
                                : '')
                            }
                            placeholder={t('fields.edit_order.Placeholder')}
                          />
                        </div>
                      ))}
                    </Form.Item>
                  }
                </div>
              </Form.Item> 

              { order?.experiments && order?.experiments?.length > 0 && 
                <div className="address-box experiments-box">
                  <h3 className="experiments-box-header">Experiments</h3>

                  { order?.experiments?.filter(experiment => !!experiment?.isIncluded)?.map(experiment => {
                    return (
                      <div className="experiment-container">
                        <div> <span className="experiment-key">Key:</span> {experiment?.test}</div>
                        <div> <span className="experiment-key">Value:</span> {typeof experiment?.value === "boolean" ? (experiment?.value ? "Yes" : "No") : experiment?.value}</div>
                        <div> <span className="experiment-key">Description:</span> {experiment?.description}</div>
                      </div>
                    )
                  }) }
                </div>
              }

              <Form.Item>
                <div className="address-box">
                  <ButtonGroup>
                    { (
                        (order?.real_cargo_company?.name === "Fedex" && order?.status === "Ready") ||
                        (order?.real_cargo_company?.name === "Fedex Standard" && order?.status === "Ready") ||
                        (order?.cargo_company?.name === "Lonca" && order?.status === "Ready")
                      ) && 
                      <Button
                        type="secondary"
                        className="button-margin"
                        onClick={handleCreateShipment}
                        loading={shipmentCreationLoading}
                      >
                        {t('fields.edit_order.Create Shipment')} - {order?.real_cargo_company?.name || order?.cargo_company?.name}
                      </Button> 
                    }
                    {
                      (order?.shipment_label) &&
                      <a href={order?.shipment_label} target='_blank' rel='noopener noreferrer'>
                        <Button
                          type="secondary"
                        >
                          {t('fields.edit_order.download_label')}
                        </Button>
                      </a>
                    }
                  </ButtonGroup>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="address-box">
                  <Button
                    type="secondary"
                    className="button-margin"
                    onClick={handleCreateInvoice}
                    loading={parasutInvoiceLoading}
                  >
                    {t('fields.edit_order.Create Parasut Invoice')}
                  </Button>
                </div>
              </Form.Item>
              <div>
               <ButtonGroup>
                <Button type="secondary" onClick={() => {
                    setInvoiceType("english");
                    setNewInvoice(null);
                    setShowInvoiceForm(true);
                  }} className='new-invoice-btn'>
                    {t('fields.edit_order.Upload Order Invoice')}
                  </Button>
                  <Button type="secondary" onClick={() => {
                    setInvoiceType("parasut");
                    setNewInvoice(null);
                    setShowInvoiceForm(true);
                  }} className='new-invoice-btn'>
                    {t('fields.edit_order.Upload Parasut Invoice')}
                  </Button>
               </ButtonGroup>
              </div>
              <Form.Item name="invoices" label={t('fields.edit_order.Available Invoices')}>
                <Select
                  onChange={(e) => {
                    const id = e;
                    const invoice = invoices?.find(i => i?._id?.toString() === id?.toString());

                    form.setFieldsValue({ invoices: null });

                    if (!order?.invoice.find(i => i._id === id)) {
                      setOrder({ ...order, invoice: [ ...order?.invoice, invoice ] });
                    }
                    
                    setSaved(false);
                  }}
                  placeholder={t('fields.edit_order.Add Invoice')}
                >
                  {invoices.map((invoice, index) => (
                    <Option key={'invoice-select' + index} value={invoice._id}>
                      {invoice?.code}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="invoices">
                <div>{t("fields.edit_order.List of selected invoices")}:</div>
                {order?.invoice?.map((invoice, index) => {
                  return (
                    <div className="invoice" key={index}>
                      <CloseOutlined onClick={() => deleteInvoice(invoice)} />
                      {invoice.code}
                      <DownloadOutlined onClick={() => {
                        window.open(invoice.link)
                      }} />
                    </div>
                  )
                })}
              </div>
              <div className="address-box">
                <Form.Item label={t('fields.edit_order.Parasut Invoice No')}>
                  <Input
                    value={order?.parasut_invoice_no}
                    onChange={(e) => {
                      setOrder({
                        ...order,
                        parasut_invoice_no: e.target.value
                      });
                      setSaved(false);
                    }}
                  />
                </Form.Item>

                <div className="english-invoice-buttons">
                  <Button 
                    onClick={() => {
                      fetchCreateEnglishInvoice({
                        orders: [order],
                        mode: ""
                      })
                        .then(() => {
                          notification['success']({
                            message: t('fields.edit_order.Invoice created successfully'),
                          });
                          setRefresh(r => !r);
                        })
                        .catch(() => {
                          notification['error']({
                            message: t("fields.edit_order.Invoice couldn't created successfully"),
                          });
                          setRefresh(r => !r);
                        })
                    }}
                  >
                    {t('fields.edit_order.Create English Invoice')}
                  </Button>

                  <Button 
                    onClick={() => {
                      fetchCreateEnglishInvoice({
                        orders: [order],
                        mode: "non-ready"
                      })
                        .then(() => {
                          notification['success']({
                            message: t('fields.edit_order.Invoice created successfully'),
                          });
                          setRefresh(r => !r);
                        })
                        .catch(() => {
                          notification['error']({
                            message: t("fields.edit_order.Invoice couldn't created successfully"),
                          });
                          setRefresh(r => !r);
                        })
                    }}
                  >
                    {t('fields.edit_order.Create English Invoice Non Ready')}
                  </Button>
                </div>
              </div>

              <div className="address-box">
                <Form.Item label={t('fields.edit_order.Mail Status')}></Form.Item>
                <MailSentStatus mail_status={order?.mail_status || order?.sendgrid_status} />
              </div>


              <Form.Item>
                <div className="address-box">
                  <Button
                    disabled={!(order?.cart_item?.some((item) => (
                       (item?.product?.vendor?.name === 'Setre' && item?.order_status === "Received") 
                    || (item?.product?.vendor?.name === 'Ebijuteri' && item?.order_status === "Received")
                    || (item?.product?.vendor?.name === 'Allday' && item?.order_status === "Received")
                    || (item?.product?.vendor?.name === 'EYYO ATELIER' && item?.order_status === "Received"))
                    && !["Created", "Payment Pending", "Delivery", "Completed", "Cancelled"].includes(order?.status)))}
                    type="secondary"
                    className="button-margin"
                    onClick={handleAutoSendOrderToVendors}
                  >
                    {t('fields.edit_order.AutoSendToVendor')}
                  </Button>
                </div>
              </Form.Item>

              <div className="address-box">
                <Button
                  type="secondary"
                  onClick={() => {
                    setLoadingSalesAgreement(true);

                    createSalesAgreement(order?._id)
                      .then(res => {
                        setLoadingSalesAgreement(false);

                        notification['success']({
                          message: "Sales agreement created",
                        });

                        setRefresh(r => !r);
                      })
                      .catch(err => {
                        setLoadingSalesAgreement(false);
                        notification['error']({
                          message: JSON.stringify(err?.response?.data?.error, null, 2),
                        });
                      })
                  }}
                  loading={loadingSalesAgreement}
                >
                  Create Sales Agreement
                </Button>

                <a href={order?.sales_agreement} target='_blank' rel='noopener noreferrer'>
                  <Button
                    type="secondary"
                  >
                    See Sales Agreement
                  </Button>
                </a>
              </div>

              <Form.Item>
                <div className="checkbox-container">
                  <label>
                  <input
                      type="checkbox"
                      checked={order?.allowed_refund}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          allowed_refund: !order.allowed_refund
                        })
                      }}
                  />
                    Allow Exchange
                  </label>
                </div>
              </Form.Item>
              <Form.Item>
                <div>
                  <Button 
                    type="secondary"
                    onClick={handleExchangeNavigation}
                    disabled={!(initialOrder?.status === "Completed")}
                  >
                      {
                        hasExchange() 
                        ? 
                          <>See Exchange</>
                        :
                          <>Create Exchange</>
                      }
                  </Button>
                </div>
              </Form.Item>
            </div>
          </div>
          
          <div className="add-new-product">
            { (!isFetchingAllProducts && products?.length === 0) && <Button
              onClick={() => {
                if (isFetchingAllProducts) return;

                setIsFetchingAllProducts(true);

                dispatch(fetchAllProductsHandle())
                  .then(() => {
                    setIsFetchingAllProducts(false);
                  })
              }}
            >
              {t('fields.edit_order.Add new product')}
            </Button>}

            { isFetchingAllProducts && <Spin/> }

            { products?.length > 0 &&
              <Form.Item >
                <Select
                  placeholder={t('fields.edit_order.Add new product')}
                  onChange={async (e) => await handleAddNewProduct(e)}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {products?.map((product, index) => (
                    <Option key={'product-select' + index} value={product._id}>
                      {product?.names?.en}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }
          </div>

          {order?.cart_item?.length ?
            <div className="cart-form-top">
              <div className="cart-form-top-bar">
                <p className="cart-form-number">{t("fields.edit_order.# of items")}: {order?.cart_item?.length}</p>

                <Button 
                  onClick={async (e) => {
                    e.stopPropagation();
                    setDownloadLoading(true);
                    try {
                      await downloadCartItemImages(order?.cart_item);
                    } catch (error) {
                      console.error('Error generating ZIP:', error);
                    } finally {
                      setDownloadLoading(false);
                    }
                  }}
                  loading={downloadLoading}
                  disabled={downloadLoading}
                >
                  Download Images
                </Button>
                <Form.Item className="cart-form-sort" name="sort-products">
                  <Select
                    onChange={(e) => { setSortProducts(e) }}
                    placeholder="Sort By"
                  >
                    <Option value="vendor">{t('fields.edit_order.Vendor')}</Option>
                    <Option value="status">{t('fields.edit_order.Status')}</Option>
                    <Option value="product-type">{t('fields.edit_order.Product Type')}</Option>
                    <Option value="is_counted">{t('fields.edit_order.Is Counted')}</Option>
                  </Select>
                </Form.Item>
              </div>

              {order?.cart_item?.map((item, index) => (
                <div className="cart_item_order" key={item._id}>
                  <div className="cart_item_image">
                    <ResponsiveImage src={item?.product?.main_image} alt={`${item?.product?.names?.en}`} height={300} />
                  </div>

                  <div className="cart_item_info">
                    <div className="inline_five_inputs_order">
                      <FormItem>
                        {t("fields.edit_order.Vendor")}
                        <Input
                          value={item?.product?.vendor?.name + (item?.sub_vendor_name ? ` - ${item?.sub_vendor_name}` : "")}
                          disabled
                        />
                      </FormItem>

                      <FormItem>
                        {t("fields.edit_order.Product Name")}
                        <Input
                          value={item?.product?.names?.en}
                          disabled
                        />
                      </FormItem>

                      <FormItem>
                        {t("fields.edit_order.Supplier Code")}
                        <Input
                          value={item?.product?.supplier_stock_code}
                          disabled
                        />
                      </FormItem>

                      <Form.Item>
                        {t('fields.edit_order.Is Counted')}
                        <br></br>
                        <Checkbox
                          checked={item.is_counted}
                          onChange={(e) => {
                            handleItemChange({ item, field: "is_counted", value: e.target.checked });
                          }}
                          disabled={ADMIN_DISABLED_ITEM_STATUSES.includes(item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(order?.status)}
                        />
                      </Form.Item>

                      {item?.is_stock_in && 
                        <Form.Item>
                          {t('fields.edit_order.Is StockIn')}
                          <br></br>
                          <Checkbox
                            checked={item?.is_stock_in}
                            onChange={(e) => {
                              handleItemChange({ item, field: "is_stock_in", value: e.target.checked });
                            }}
                            disabled={ADMIN_DISABLED_ITEM_STATUSES.includes(item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(order?.status)}
                          />
                        </Form.Item>
                      }
                    </div>

                    <div className="inline_four_inputs_order">
                      <Form.Item>
                        {t('fields.product.Status')}
                        <Select
                          value={item.order_status}
                          onChange={(e) => {
                            handleItemChange({ item, field: "order_status", value: e });
                          }}
                          className="select-input-width-px"
                          disabled={ADMIN_DISABLED_ITEM_STATUSES.includes(item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(order?.status)}
                        >
                          <Option key="Received" value="Received">{t('fields.edit_order.Received')}</Option>
                          <Option key="Confirmed" value="Confirmed">{t('fields.edit_order.Confirmed')}</Option>
                          <Option key="Returned" value="Returned">{t('fields.edit_order.Returned')}</Option>
                          <Option key="Wrong / Missing Items" value="Wrong / Missing Items">{t('fields.edit_order.Wrong / Missing Items')}</Option>
                          <Option key="StockIn" value="StockIn">{t('fields.edit_order.StockIn')}</Option>
                          <Option key="StockOut" value="StockOut">{t('fields.edit_order.StockOut')}</Option>
                          <Option key="Reviewed" value="Reviewed">{t('fields.edit_order.Reviewed')}</Option>
                          <Option key="Defected" value="Defected">{t('fields.edit_order.Defected')}</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item name="series">
                        {t('fields.edit_order.Series')}
                        <Select
                          value={item.series}
                          onChange={(e) => {
                            handleItemChange({ item, field: "series", value: e });
                          }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          className="select-input-width"
                          disabled={ADMIN_DISABLED_ITEM_STATUSES.includes(item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(order?.status)}
                        >
                          {seriesMain.map((serie, index) => (
                            <Option key={'series-select' + index} value={serie.name}>
                              {serie.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <FormItem>
                        {`${t('fields.edit_order.Quantity')}`}
                        <br />
                        <InputNumber
                          style={{width: "100%"}}
                          value={item.quantity}
                          onChange={(e) => {
                            handleItemChange({ item, field: "quantity", value: e });
                          }}
                          disabled={ADMIN_DISABLED_ITEM_STATUSES.includes(item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(order?.status)}
                        />
                      </FormItem>

                      <Form.Item>
                        {t('fields.edit_order.Item Count')}

                        <InputNumber
                          style={{width: "100%"}}
                          value={item.item_count}
                          disabled
                        />
                      </Form.Item>
                    </div>

                    <div className="inline_four_inputs_order">
                      <Form.Item>
                        {t("fields.edit_order.Unit Price")}
                        <InputNumber
                          style={{width: "100%"}}
                          prefix="$"
                          value={`${
                            loncaRound(calculateItemPrice({ item, order: true }))
                            } ${
                            orderCurrencySymbol !== "$"
                            ? `- ${orderCurrencySymbol}${loncaRound(calculateItemPrice({ item, order: true }) * order.rate_usd_selling)}`
                            : ""
                            }`}
                          disabled
                        />
                      </Form.Item>

                      <Form.Item>
                        {t("fields.edit_order.Unit Price (with VAT)")}
                        <InputNumber
                          style={{width: "100%"}}
                          prefix="$"
                          value={`${loncaRound(calculateItemPriceWithVAT({ item, order: true }))} ${
                          orderCurrencySymbol !== "$"
                          ? `- ${orderCurrencySymbol}${loncaRound(calculateItemPriceWithVAT({ item, order: true }) * order.rate_usd_selling)}`
                          : ""
                          }`}
                          disabled
                        />
                      </Form.Item>

                      <div>
                        <Form.Item>
                          {t("fields.edit_order.Total Price")}
                          <InputNumber
                            style={{width: "100%"}}
                            value={`$${loncaRound(calculateItemPrice({ item, order: true }) * item.item_count * item.quantity)} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${loncaRound(calculateItemPrice({ item, order: true }) * item.item_count * item.quantity * order.rate_usd_selling)}` : ""}`}
                            disabled
                          />
                        </Form.Item>
                      </div>

                      <div className="cart-item-notes">
                        <Form.Item>
                          {t('fields.edit_order.Supplier Notes')}:
                          <TextArea
                            value={item?.notes}
                            className="text-area-height"
                            onChange={(e) => {
                              handleItemChange({ item, field: "notes", value: e.target.value });
                              setSaved(false);
                            }}
                            placeholder={t('fields.edit_order.Supplier Notes')}
                            disabled
                          />
                        </Form.Item>
                      </div>

                      <div className="cart-item-notes">
                        <Form.Item>
                          {t('fields.edit_order.Admin Notes')}:
                          <TextArea
                            value={item?.notes_admin}
                            className="text-area-height"
                            onChange={(e) => {
                              handleItemChange({ item, field: "notes_admin", value: e.target.value });
                              setSaved(false);
                            }}
                            placeholder={t('fields.edit_order.Admin Notes')}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="inline_four_inputs_order">
                      {item?.order_addition_date &&
                        <FormItem>
                          {t('fields.order_products.Order Addition Date')}
                          <Input
                            value={loncaFormatDate(item?.order_addition_date)}
                            disabled
                          />
                        </FormItem>
                      }
                      {item?.status_log?.map((status_log) => {
                        if(status_log?.status === "Confirmed") {
                          return (
                          <>
                            <FormItem>
                              {t('fields.order_products.Confirm Date')}
                              <Input
                                value={loncaFormatDate(status_log?.date)}
                                disabled
                              />
                            </FormItem>

                            <FormItem>
                              {t('fields.order_products.Confirm Person')}
                              <Input
                                value={status_log?.owner}
                                disabled
                              />
                            </FormItem>                         
                          </>
                          )
                        }
                        if(status_log?.status === "Reviewed") {
                          return (
                          <>
                            <FormItem>
                              {t('fields.order_products.Review Date')}
                              <Input
                                value={loncaFormatDate(status_log?.date)}
                                disabled
                              />
                            </FormItem>

                            <FormItem>
                              {t('fields.order_products.Review Person')}
                              <Input
                                value={status_log?.owner}
                                disabled
                              />
                            </FormItem>
                          </>
                          )
                        }
                      })}             
                    </div>

                    <div className="inline_four_inputs_order">
                      {item?.is_counted_log ?
                        <FormItem>
                        {t('fields.order_products.Count Date')}
                        <Input
                          value={loncaFormatDate(item?.is_counted_log?.date)}
                          disabled
                        />
                        </FormItem>
                      : null}
                      {item?.is_counted_log ?
                          <FormItem>
                            {t('fields.order_products.Count Person')}
                            <Input
                              value={item?.is_counted_log?.owner}
                              disabled
                            />
                            </FormItem>
                         : null }
                      {item?.barcode_url && <Button 
                      onClick={() => handleBarcode(item?.barcode_url, item?._id, item?.series)}
                      loading={barcodeButtonLoading}
                      disabled={barcodeButtonLoading}>
                      Download Barcode
                    </Button>}
                    </div>

                  </div>
                        
                  <div className="close-price">
                    <div>
                      <Button
                        icon={<CloseOutlined />}
                        onClick={() => {
                          handleDeleteCartItem({ item });
                          setSaved(false);
                        }}
                      ></Button>
                      <Button
                        disabled={changedCartItems?.find(i => i?.item?._id?.toString() === item?._id?.toString())?.type === "add"}
                        onClick={() => {
                          handleCloneCartItem({ item, index });
                        }}
                      >
                        {t('fields.edit_order.Clone')}
                      </Button>
                    </div>
                  </div>

                </div>
              ))}
            </div>
            : null}
        </Form>
      </div >
    </div >
  )

  if (showInvoiceForm) {
    return (
      <EditInvoice
        invoice={{ ...newInvoice, customer: order?.customer }}
        setInvoice={setNewInvoice}
        setShowForm={setShowInvoiceForm}
        newForCustomer={true}
        setInvoiceModal={setInvoiceModal}
        setInvoiceRefresh={setInvoiceRefresh}
        invoiceRefresh={invoiceRefresh}
        invoiceType={invoiceType}
      />
    );
  }
};

export default EditOrder;