import { useEffect } from "react"
import { notification } from "antd";
import { LONCA_INVOICE_ERRORS, LONCA_INVOICE_SUCCESS_NOTIFS } from "../constants";
import { fetchAllLoncaInvoices, fetchInvoiceById } from "../../../redux/loncaInvoiceSlice";
import moment from "moment";

export const useGetVendors = (vendorStatus, fetchVendors, dispatch) => {
    useEffect(() => {
        if(vendorStatus !== "succeeded") {
            dispatch(fetchVendors());
        }
    }, [vendorStatus, fetchVendors, dispatch]);
}

export const useSetVendors = (vendorStatus, vendors, setActiveVendors) => {
    useEffect(() => {
        if(vendorStatus === "succeeded") {
            const activeVendors = vendors?.filter((v) => v?.status === "Active");
            const activeAndSortedVendors = activeVendors?.sort((a, b) => a?.name.localeCompare(b?.name));
            setActiveVendors(activeAndSortedVendors);
        }
    }, [vendorStatus, vendors, setActiveVendors]);
}

export const useSetSubVendors = (vendorStatus, vendors, selectedVendor, setSubVendors) => {
    useEffect(() => {
        if(vendorStatus === "succeeded") {
            const vendor = vendors?.find((vendor) => vendor?.name === selectedVendor);
            if(vendor?.sub_vendors?.length > 0) {
                setSubVendors(vendor?.sub_vendors?.map((sub_vendor) => sub_vendor?.name));
            } else {
                setSubVendors([]);
            }
        }
    }, [vendorStatus, vendors, selectedVendor, setSubVendors])
}

export const useGetNoInvoiceOrderProductsByVendor = (fetchNoInvoiceOrderProductsByVendor, selectedVendor, selectedSubVendor, dispatch) => {
    useEffect(() => {
        if(selectedVendor !== "") {
            dispatch(fetchNoInvoiceOrderProductsByVendor({ selectedVendor, selectedSubVendor }));
        }
    }, [fetchNoInvoiceOrderProductsByVendor, selectedVendor, selectedSubVendor, dispatch]);
}

export const useGetInvoicesByVendor = (filter, dispatch) => {
    useEffect(() => {
        dispatch(fetchAllLoncaInvoices({ ...filter }))
    }, [filter, dispatch]);
}

export const handleVendorChange = (selectedVendorValue, setSelectedVendor) => {
    setSelectedVendor(selectedVendorValue);
}

export const handleSubmit = async (
    selectedProducts, 
    selectedVendor, 
    selectedSubVendor, 
    invoiceNumber, 
    invoiceDate, _id, 
    handleFunction, 
    navigate,
    setIsSaveAvailable,
    invoiceExists,
    t
) => {
    const successKey = invoiceExists ? "EDIT" : "ADD";
    setIsSaveAvailable(false);

    const errorKey = handleErrors({
        selectedProducts: selectedProducts, 
        selectedVendor: selectedVendor, 
        invoiceNumber: invoiceNumber, 
        invoiceDate: invoiceDate,
    });

    if(errorKey) {
        notification.error({
            message: t(LONCA_INVOICE_ERRORS[errorKey].message),
            description: t(LONCA_INVOICE_ERRORS[errorKey].description),
            duration: 2.5
        });
        setIsSaveAvailable(true);
    } else {
        try {
            await handleFunction({
                _id: _id,
                selectedProducts: selectedProducts,
                selectedVendor: selectedVendor,
                selectedSubVendor: selectedSubVendor,
                invoiceNumber: invoiceNumber,
                invoiceDate: invoiceDate
            });
    
            notification.success({
                message: t(LONCA_INVOICE_SUCCESS_NOTIFS[successKey].message),
                description: t(LONCA_INVOICE_SUCCESS_NOTIFS[successKey].description)
            });
    
            setTimeout(() => {
                navigate("../lonca-invoices");
            }, 1500);
            setIsSaveAvailable(true);
        } catch (error) {
            notification.success({
                message: t(LONCA_INVOICE_ERRORS.SERVER_ERROR.message),
                description: t(LONCA_INVOICE_ERRORS.SERVER_ERROR.description)
            });
        }
    }
}

export const useGetInvoiceById = (_id, invoiceExists, dispatch) => {
    useEffect(() => {
        try {
            if(invoiceExists) {
                dispatch(fetchInvoiceById({
                    _id: _id
                }));
            }
        } catch (error) {
            console.log(error)
        }
    }, [_id, invoiceExists, dispatch])
}

export const useGetInvoiceData = (
    setSelectedVendor,
    setSelectedSubVendor,
    setInvoiceNumber,
    setInvoiceDate,
    setSelectedProducts,
    invoiceStatus,
    invoiceExists,
    invoice
) => {
    useEffect(() => {
        if(invoiceExists && invoiceStatus === "fulfilled") {
            setSelectedVendor(invoice?.vendor_name);
            setSelectedSubVendor(invoice?.sub_vendor_name);
            setInvoiceNumber(invoice?.invoice_number);
            setInvoiceDate(moment(invoice?.invoice_date));
            setSelectedProducts(invoice?.invoice_items);
        }
    }, [setSelectedVendor,
        setSelectedSubVendor,
        setInvoiceNumber,
        setInvoiceDate,
        setSelectedProducts,
        invoiceStatus,
        invoiceExists,
        invoice
    ]);
}

const handleErrors = ({
    selectedProducts: selectedProducts, 
    selectedVendor: selectedVendor, 
    invoiceNumber: invoiceNumber, 
    invoiceDate: invoiceDate
}) => {
    let errorKey;

    if (!selectedVendor) {
        errorKey = "VENDOR_ERROR"
    } else if (!invoiceNumber) {
        errorKey = "INVOICE_NUMBER_ERROR"
    } else if (!invoiceDate) {
        errorKey = "INVOICE_DATE_ERROR"
    } else if (selectedProducts.length === 0) {
        errorKey = "PRODUCT_ERROR"
    }

    return errorKey;
}