import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './App.css';
import { MainLayout } from './layouts';
import { fetchRoles } from './redux/roleSlice';
import { setSelectedLanguage } from './redux/userSlice';
import { LANGUAGE_ROUTES, LANGUAGES } from './constants';

import {
  Products,
  Vendors,
  EditVendor,
  Series,
  Tags,
  EditTag,
  Filters,
  EditFilter,
  Coupons,
  Customers,
  SupplierUsers,
  EditSupplierUser,
  Carts,
  Orders,
  Login,
  AdditionalServices,
  EditProduct,
  EditNewProduct,
  Invoices,
  ProductTypes,
  Colors,
  OrderProducts,
  OrderStatus,
  ProductMatching,
  NewProducts,
  EditOrder,
  EditCustomer,
  EditCart,
  CreditStatuses,
  CreditApplications,
  AllOrders,
  AllLogisticOrders,
  Refunds,
  RefundProducts,
  SupplierInvoices,
  EditSupplierInvoice,
  EditRefund,
  EditSupplierApplication,
  AdminRoles,
  EditAdminRole,
  Shelves,
  EditCargoCompany,
  TagProducts,
  ProductDetails,
  StaticContents,
  EditStaticContent,
  FirstMileShipments,
  Favorites,
  EditFavorites,
  SupplierAuthority,
  MarketingFlows,
  EditMarketingFlows,
  Configurations,
  LogisticsOrders,
  EditLogisticsOrder,
  LogisticsCustomers,
  EditLogisticsCustomer,
  LoncaInvoices,
  AddLoncaInvoice
} from './pages';
import SupplierApplications from './pages/SupplierApplications/SupplierApplications';
import CargoCompanies from './pages/CargoCompanies/CargoCompanies';
import { TestModeSwitch } from './components';
import CSVDiscountedProducts from './pages/CSVDiscountedProducts/CSVDiscountedProducts';
import AddExchange from './pages/AddExchange/AddExchange';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { i18n } = useTranslation('translation');
  const { status: userStatus, user, languagePath, selectedLanguage } = useSelector((state) => state.user);
  const { roles } = useSelector((s) => s.role);

  if (selectedLanguage?.code !== i18n.language) i18n.changeLanguage(selectedLanguage?.code);

  useEffect(async () => {
    dispatch(fetchRoles())
  }, []);

  useEffect(() => {
    if (userStatus.auth !== "succeeded" && !localStorage.getItem("lonca-admin-token")) {
      const currentLanguagePath = window.location.pathname.split("/")[1];
      const pathname = LANGUAGES.map(l => l.code).includes(currentLanguagePath) ? "/" + window.location.pathname.split("/").slice(2).join("/") :
        window.location.pathname;

      navigate(`${languagePath}/login?path=${pathname}`)
    };
  }, [])


  useEffect(() => {
    const urlLanguage = window?.location?.pathname?.split('/')[1];
    if (LANGUAGES.map((l) => l.code).includes(urlLanguage)
    && LANGUAGES.find((l) => l.code === urlLanguage)?.code !== selectedLanguage?.code) {
      dispatch(setSelectedLanguage(LANGUAGES.find((l) => l.code === urlLanguage)));
    }
  }, [dispatch, selectedLanguage]);

  const havePermission = (tab) => {
    if (!userStatus.auth === 'succeeded') return false;

    const role = roles?.filter((role) => role?.name === user?.admin_role)[0]
    return role?.tabs?.includes(tab);
  };

  return (
    <div>
      {LANGUAGE_ROUTES.map((route) => (
        <div className="App">
          <Routes>

            <Route path={`${route}/login`} element={<Login />} />

            <Route path={route} element={<MainLayout />}>
              { havePermission('Products') && <Route path="products" element={<Products />} /> }
              { havePermission('Products') && <Route path="product/:id" element={<EditProduct />} /> }
              { havePermission('Tag Products') && <Route path="tag-products" element={<TagProducts />} /> }
              { havePermission('Product Details') && <Route path="product-details" element={<ProductDetails />} /> }
              { havePermission('Static Contents') && <Route path="static-contents" element={<StaticContents />} /> }
              { havePermission('Static Contents') && <Route path="static-contents/:type" element={<EditStaticContent />} /> }
              { havePermission('CSV Discounted Products') && <Route path="csv-discounted-products" element={<CSVDiscountedProducts />} /> }
              { havePermission('Vendors') && <Route path="vendors" element={<Vendors />} /> }
              { havePermission('Vendors') && <Route path="vendor/:name" element={<EditVendor />} /> }
              { havePermission('Cargo Companies') && <Route path="cargo-companies" element={<CargoCompanies />} /> }
              { havePermission('Cargo Companies') && <Route path="cargo-company/:name" element={<EditCargoCompany />} /> }
              { havePermission('Series') && <Route path="series" element={<Series />} /> }
              { havePermission('Tags') && <Route path="tags" element={<Tags />} /> }
              { havePermission('Tags') && <Route path="tag/:id" element={<EditTag />} /> }
              { havePermission('Filters') && <Route path="filters" element={<Filters />} /> }
              { havePermission('Filters') && <Route path="filter/:id" element={<EditFilter />} /> }
              { havePermission('Coupons') && <Route path="coupons" element={<Coupons />} /> }
              { havePermission('Customers') && <Route path="customers" element={<Customers />} /> }
              { havePermission('Customers') && <Route path="customers/:id" element={<EditCustomer />} /> }
              { havePermission('Logistics Customers') && <Route path="logistics-customers" element={<LogisticsCustomers />} /> }
              { havePermission('Logistics Customers') && <Route path="logistics-customers/:id" element={<EditLogisticsCustomer />} /> }
              { havePermission('Admin Roles') && <Route path="roles" element={<AdminRoles />} /> }
              { havePermission('Admin Roles') && <Route path="roles/:id" element={<EditAdminRole/>} /> }
              { havePermission('Users') && <Route path="users" element={<SupplierUsers />} /> }
              { havePermission('Users') && <Route path="users/:id" element={<EditSupplierUser />} /> }
              { havePermission('Carts') && <Route path="carts" element={<Carts />} /> }
              { havePermission('Carts') && <Route path="carts/:id" element={<EditCart />} /> }
              { havePermission('Favorites') && <Route path="favorites" element={<Favorites />} /> }
              { havePermission('Favorites') && <Route path="favorites/:id" element={<EditFavorites />} /> }
              { havePermission('Orders') && <Route path="orders" element={<Orders />} /> }
              { havePermission('Orders') && <Route path="orders/:id" element={<EditOrder />} /> }
              { havePermission('Logistics Orders') && <Route path="logistics-orders" element={<LogisticsOrders />} /> }
              { havePermission('Logistics Orders') && <Route path="logistics-orders/:id" element={<EditLogisticsOrder />} /> }
              { havePermission('Shelves') && <Route path="shelves" element={<Shelves />} /> }
              { havePermission('Order Products') && <Route path="order-products" element={<OrderProducts type="order_products"/>} /> }
              { havePermission('Order Products') && <Route path="late-products" element={<OrderProducts type="late_products"/>} /> }
              { havePermission('Order Status') && <Route path="order-status" element={<OrderStatus />} /> }
              { havePermission('Additional Services') && <Route path="additionalServices" element={<AdditionalServices />} /> }
              { havePermission('Customer Invoices') && <Route path="invoices" element={<Invoices />} /> }
              { havePermission('Product Types') && <Route path="productTypes" element={<ProductTypes />} /> }
              { havePermission('Colors') && <Route path="colors" element={<Colors />} /> }
              { havePermission('Product Matching') && <Route path="product-matching" element={<ProductMatching />} /> }
              { havePermission('New Products') && <Route path="new-products" element={<NewProducts />} /> }
              { havePermission('New Products') && <Route path="new-product/:id" element={<EditNewProduct />} /> }
              { havePermission('Credit Status') && <Route path="credit-status" element={<CreditStatuses />} /> }
              { havePermission('Credit Applications') && <Route path="credit-applications" element={<CreditApplications />} /> }
              { havePermission('All Orders') && <Route path="all-orders" element={<AllOrders />} /> }
              { havePermission('All Logistic Orders') && <Route path='all-logistic-orders' element={<AllLogisticOrders />} />}
              { havePermission('Refunds') && <Route path="refunds" element={<Refunds />} /> }
              { havePermission('Refund Products') && <Route path="refund-products" element={<RefundProducts />} /> }
              { havePermission('Supplier Invoices') && <Route path="supplier-invoices" element={<SupplierInvoices />} />}
              { havePermission('Supplier Invoices') && <Route path="supplier-invoice/:handle/:vendor_id" element={<EditSupplierInvoice />} />}
              { havePermission('Refunds') && <Route path="add-refund" element={<AddExchange />} /> }
              { havePermission('Refunds') && <Route path="refunds/:id" element={<EditRefund />} /> }
              { havePermission('Supplier Applications') && <Route path="supplier-applications" element={<SupplierApplications />} />}
              { havePermission('Supplier Applications') && <Route path="supplier-applications/:id" element={<EditSupplierApplication />} />}
              { havePermission('Admin Roles') && <Route path="test-mode" element={<TestModeSwitch />} />}
              { havePermission('First Mile Shipments') && <Route path="first-mile-shipments" element={<FirstMileShipments />} /> }
              { havePermission('Supplier Authority') && <Route path="supplier-authority" element={<SupplierAuthority />} /> }
              { havePermission('Marketing Flows') && <Route path="marketing-flows" element={<MarketingFlows />} />}
              { havePermission('Marketing Flows') && <Route path="marketing-flows/:id" element={<EditMarketingFlows />} />}
              { havePermission('Configurations') && <Route path="configurations" element={<Configurations />} /> }
              { havePermission('Lonca Supplier Invoices') && <Route path="lonca-invoices" element={<LoncaInvoices />} /> }
              { havePermission('Lonca Supplier Invoices') && <Route path="add-lonca-invoice" element={<AddLoncaInvoice />} /> }
              { havePermission('Lonca Supplier Invoices') && <Route path="lonca-invoices/:id" element={<AddLoncaInvoice />} /> }
            </Route>
          </Routes>
        </div>
      ))}
    </div>
  );
}

export default App;
