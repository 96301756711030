import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LoncaInvoices.scss";
import { Button, Form, Select, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendors } from "../../redux/vendorSlice";
import {  useGetInvoicesByVendor, useGetVendors, useSetSubVendors, useSetVendors } from "../AddLoncaInvoice/functions/helper";
import { Pagination } from "../../components";
import { setLoncaInvoiceFilter } from "../../redux/loncaInvoiceSlice";
import { handleNavigateToUpdate, navigateToAddInvoice } from "./functions/helper";
import { loncaFormatDate } from "../../helpers";

const LoncaInvoices = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const { vendors, status: vendorStatus } = useSelector(state => state.vendors);
    const { allInvoices, filter } = useSelector(state => state.loncaInvoice);

    const [ activeVendors, setActiveVendors ] = useState([]);
    const [ activeSubVendors, setActiveSubVendors ] = useState([]);

    useGetVendors(vendorStatus.fetchVendors, fetchVendors, dispatch);
    useSetVendors(vendorStatus.fetchVendors, vendors, setActiveVendors);
    useSetSubVendors(vendorStatus.fetchVendors, activeVendors, filter.vendor, setActiveSubVendors);
    useGetInvoicesByVendor(filter, dispatch)

    const setFilter = useCallback((value) => {
        dispatch(setLoncaInvoiceFilter(value));
    }, [dispatch]);

    const setVendor = useCallback((vendorName) => {
        dispatch(setLoncaInvoiceFilter({
            ...filter,
            vendor: vendorName,
            subVendor: ""
        }));
    });

    const setSubVendor = useCallback((subVendorName) => {
        dispatch(setLoncaInvoiceFilter({
            ...filter,
            subVendor: subVendorName
        }));
    });

    const setSort = useCallback((sortObject) => {
        dispatch(setLoncaInvoiceFilter({
            ...filter,
            sort: JSON.parse(sortObject)
        }));
    });

    const COLUMN_NAMES = [
        {
            title: t("home.vendor"),
            dataIndex: 'vendor_name',
            key: 'vendor_name'
        },
        {
            title: t("home.Sub Vendor"),
            dataIndex: 'sub_vendor_name',
            key: 'sub_vendor_name',
            render: (text) => text ? text : "-"
        },
        {
            title: t("lonca_invoices.product_count"),
            dataIndex: 'invoice_items',
            key: 'invoice_items',
            render: (items) => items?.length
        },
        {
            title: t("lonca_invoices.invoice_number"),
            dataIndex: 'invoice_number',
            key: 'invoice_number'
        },
        {
            title: t("lonca_invoices.invoice_date"),
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            render: (date) => date ? loncaFormatDate(date) : "-"
        }
    ]
    
    return (
        <div className="lonca-invoices-main-container">
            <div className="lonca-invoices-header">
                <h2>{t('home.Lonca Supplier Invoices')}</h2>
                <Button type="ghost" onClick={() => navigateToAddInvoice(navigate)}>
                    <span>{t("lonca_invoices.add_new_invoice")}</span>
                </Button>
            </div>

            <div className="lonca-invoices-filters">
                <Form.Item className="lonca-invoices-form-item">
                    <Select 
                        onChange={setVendor}
                        value={filter.vendor}
                        showSearch
                    >
                        {
                            <Option key={"All"} value={""} >
                                {t("fields.edit_order.All Vendors")}
                            </Option>
                        }
                        {
                            activeVendors?.map((vendor) => {
                                return (
                                    <Option key={vendor?._id} value={vendor?.name}>
                                        {vendor?.name}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item className="lonca-invoices-form-item">
                    <Select 
                        onChange={setSubVendor}
                        value={filter.subVendor}
                        showSearch
                        disabled={!activeSubVendors || activeSubVendors?.length === 0}
                    >
                        {
                            <Option key={"All"} value={""} >
                                {t("lonca_invoices.all_sub_vendors")}
                            </Option>
                        }
                        {
                            activeSubVendors?.map((vendor) => {
                                return (
                                    <Option key={vendor} value={vendor}>
                                        {vendor}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                </Form.Item>

                <Form.Item className="lonca-invoices-form-item">
                    <Select 
                        onChange={setSort}
                        value={JSON.stringify(filter.sort)}
                        showSearch
                    >
                        <Option key={"new_to_old"} value={JSON.stringify({invoice_date: -1})}>
                            {t("sort.New to Old")}
                        </Option>
                        <Option key={"old_to_new"} value={JSON.stringify({invoice_date: 1})}>
                            {t("sort.Old to New")}
                        </Option>
                    </Select>
                </Form.Item>
            </div>

            <Table 
                columns={COLUMN_NAMES}
                dataSource={allInvoices}
                sticky
                bordered
                rowClassName="lonca-invoices-table-row"
                onRow={(record) => ({
                    onClick: () => handleNavigateToUpdate(record, navigate)
                })}
                pagination={false}
            />
            
            <Pagination filter={filter} setFilter={setFilter}/>
        </div>
    );
}

export default LoncaInvoices;