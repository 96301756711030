import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Form, Button, Image, Spin, Input, Upload, notification, Modal, Select, Collapse } from "antd";
import TextArea from "antd/lib/input/TextArea";

import { fetchStaticContent, addStaticContent, removeStaticContent, updateStaticContent } from "../../../redux/staticContentSlice";
import { fetchUploadUrl } from "../../../redux/productSlice";

import "../EditStaticContent.scss";

const EditHomePageTopVendors = () => {
    const { Option } = Select;
    const { Panel } = Collapse;

    const type = useParams()?.type;

    const { vendors } = useSelector((state) => state.vendors);

    const [contents, setContents] = useState([]);
    const [initialContents, setInitialContents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [additionLoading, setAdditionoading] = useState(false);
    const [updatedContent, setUpdatedContent] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [removeVendorModal, setRemoveVendorModal] = useState(false);
    const [removeContentId, setRemoveContentId] = useState();
    const [showNewForm, setShowNewForm] = useState(false);
    const [newItem, setNewItem] = useState({});

    useEffect(() => {
        setLoading(true);

        fetchStaticContent(type)
            .then(data => {
                setContents(data);
                setInitialContents(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }, [refresh]);

    const handleFieldChange = (contentId, field, value) => {
        setUpdatedContent(updated => {
            return {
                ...updated,
                [contentId]: { 
                    ...updated?.[contentId],
                    [field]: value
                }
            }
        });
    }

    const handleNewItemFieldChange = (field, value) => {
        setNewItem(item => ({ ...item, [field]: value }));
    }

    const handleSave = async () => {
        setSaveLoading(true);

        const copyUpdatedObject = { ...updatedContent };
        const updatedContentIds = Object.keys(copyUpdatedObject || {});

        await Promise.all(updatedContentIds?.map(async contentId => {
            const updateObject = copyUpdatedObject?.[contentId];
            
            if (updateObject?.carousel_image?.uid) {
                const uploadUrl = `vendor/${contents?.[contentId]?.handle}/carousel_image/${new Date()?.getTime()}`;

                await fetchUploadUrl(uploadUrl, "image/webp")
                    .then(async response => {
                        await fetch(response.data, {
                            method: "PUT",
                            body: updateObject?.carousel_image,
                        })

                        const imageUrl = response.data.split('?')[0];
                        updateObject.carousel_image = imageUrl;
                    })
                    .catch(() => {})
            }
        }));

        updateStaticContent(type, copyUpdatedObject)
            .then(() => {
                notification['success']({
                    message: `Updated succesfully`
                });
                setUpdatedContent({});
                setRefresh(r => !r);
                setSaveLoading(false);
            })
            .catch(() => {
                notification['error']({
                    message: `Update failed!`
                });
                setSaveLoading(false);
            });
    };


    const handleRemove = () => {
        setRemoveLoading(true);

        removeStaticContent(type, removeContentId)
            .then(() => {
                notification['success']({
                    message: `Removed succesfully`
                });
                setUpdatedContent({});
                setRefresh(r => !r);
                setRemoveLoading(false);
                setRemoveVendorModal(false);
            })
            .catch(() => {
                notification['error']({
                    message: `Remove failed!`
                });
                setRemoveLoading(false);
                setRemoveVendorModal(false);
            });
    };

    const handleNewItemSave = async () => {
        setAdditionoading(true);

        const missingFields = [];

        if (!newItem?.vendor?._id) missingFields.push("Vendor");
        if (!newItem?.carousel_image?.uid) missingFields.push("Image");
        if (!newItem?.seo_about) missingFields.push("Description");

        if (missingFields?.length > 0) {
            notification['error']({
                message: `${missingFields?.join(", ")} cannot be empty!`
            });
            setAdditionoading(false);
            return;
        }

        const copyNewItem = { ...newItem };

        if (newItem?.carousel_image?.uid) {
            const uploadUrl = `vendor/${newItem?.vendor?.handle}/carousel_image/${new Date()?.getTime()}`;

            await fetchUploadUrl(uploadUrl, "image/webp")
                .then(async response => {
                    await fetch(response.data, {
                        method: "PUT",
                        body: newItem?.carousel_image
                    })

                    const imageUrl = response?.data?.split('?')[0];
                    copyNewItem.carousel_image = imageUrl;
                })
                .catch(() => {})
        }

        addStaticContent(type, copyNewItem)
            .then(() => {
                notification['success']({
                    message: `Created succesfully`
                });
                setNewItem({});
                setShowNewForm(false);
                setRefresh(r => !r);
                setAdditionoading(false);
            })
            .catch(() => {
                notification['error']({
                    message: `Creation failed!`
                });
                setAdditionoading(false);
            });
    }

    return (
        <div className="edit-static-content-container">
            <Modal
                title="Remove Vendor"
                visible={removeVendorModal}
                onCancel={() => setRemoveVendorModal(false)}
                onOk={() => handleRemove()}
            >
                <p>Do you want to remove this vendor from top vendors?</p>
            </Modal>

            <div className="edit-static-content-header">
                <h2>Edit Static Content - { type?.split('-')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ') } { loading && <Spin /> }</h2>
                <div className="edit-static-content-header-buttons">
                    <Button 
                        onClick={() => setShowNewForm(true)} 
                        disabled={loading}
                    >
                        Add New
                    </Button>
                    <Button 
                        type="primary"
                        loading={saveLoading} 
                        onClick={handleSave} 
                        disabled={Object.keys(updatedContent)?.length === 0}
                    >
                        Save
                    </Button>
                </div>
            </div>

            {
                (type === "homepage-top-vendors" && contents?.length > 0) &&
                <div className="homepage-top-vendors">

                    { showNewForm &&
                        <Form layout="vertical">
                            <div className="homepage-top-vendor-item">
                                <div className="homepage-top-vendor-item-header">
                                    <h2>{ newItem?.vendor?.name || "" }</h2>
                                    <div className="homepage-top-vendor-item-header-buttons">
                                        <Button 
                                            type="primary" 
                                            onClick={() => setShowNewForm(false)}
                                        >
                                            Cancel
                                        </Button>
                                        <Button 
                                            type="primary" 
                                            onClick={handleNewItemSave}
                                            loading={additionLoading}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>

                                <div className="homepage-top-vendor-item-fields">
                                    <div className="left-part">
                                        <Image width={200} src={newItem?.carousel_image?.preview || ""}/>
                                    </div>

                                    <div className="right-part">
                                        <h3>Parameters</h3>

                                        <Form.Item name="vendor" label="Vendor">
                                            <Select
                                                onChange={(e) => handleNewItemFieldChange("vendor", vendors?.find(v => v?._id?.toString() === e))}
                                                showSearch
                                                className="sort-order-input"
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                                                }
                                            >
                                                { (vendors || [])
                                                    .filter(v => !contents?.find(content => content?._id?.toString() === v?._id?.toString()))
                                                    .slice()
                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                    .map((vendor, index) => (
                                                        <Option key={'vendor-select' + index} value={vendor._id}>
                                                            {vendor.name}
                                                        </Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>

                                        <Form.Item label="Sort Order (Smaller numbers appears at top)">
                                            <Input 
                                                className={"sort-order-input"}
                                                value={newItem?.sort_order}
                                                onChange={(e) => {
                                                    handleNewItemFieldChange("sort_order", !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : "" )
                                                }}
                                            />
                                        </Form.Item>

                                        <Upload
                                            showUploadList={false}
                                            onChange={async ({ file }) => {
                                                const getBase64 = (file) => {
                                                    return new Promise((resolve, reject) => {
                                                        const reader = new FileReader();
                                                        reader.readAsDataURL(file);
                                                        reader.onload = () => resolve(reader.result);
                                                        reader.onerror = (error) => reject(error);
                                                    });
                                                }

                                                const preview = await getBase64(file.originFileObj);
                                                let fileObject = file.originFileObj;
                                                fileObject.preview = preview;
                                                handleNewItemFieldChange("carousel_image", fileObject);
                                            }}
                                        >
                                            <Button>Upload New Image</Button>
                                        </Upload>

                                        <div className="descriptions">
                                            <Form.Item 
                                                className=""
                                                label="Description (English)"
                                            >
                                                <TextArea 
                                                    value={newItem?.seo_about || ""}
                                                    autoSize
                                                    className={"sort-order-input"}
                                                    onChange={(e) => {
                                                        handleNewItemFieldChange("seo_about", e.target.value)
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form> 
                    }
                        

                    {
                        contents?.map(content => {
                            const updateObject = updatedContent?.[content?._id?.toString()] || {};
                            const descriptionLanguages = Object.keys(content?.seo_abouts || {});

                            return (
                                <Form layout="vertical">
                                    <div className="homepage-top-vendor-item">
                                        <div className="homepage-top-vendor-item-header">
                                            <h2>{ content?.name }</h2>
                                            <div className="homepage-top-vendor-item-header-buttons">
                                                <Button 
                                                    type="primary" 
                                                    disabled={Object.keys(updateObject)?.length === 0}
                                                    onClick={() => {
                                                        let updateCopy = { ...updatedContent };
                                                        delete updateCopy?.[content?._id?.toString()];
                                                        setUpdatedContent(updateCopy);
                                                    }}
                                                >
                                                    Discard Changes
                                                </Button>
                                                <Button 
                                                    type="primary" 
                                                    className="homepage-top-vendor-item-remove"
                                                    loading={removeLoading}
                                                    onClick={() => {
                                                        setRemoveContentId(content?._id);
                                                        setRemoveVendorModal(true)
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="homepage-top-vendor-item-fields">
                                            <div className="left-part">
                                                <Image width={200} src={(typeof updateObject?.carousel_image?.preview !== "undefined" && !loading) ? updateObject?.carousel_image?.preview : content?.carousel_image}/>
                                            </div>

                                            <div className="right-part">
                                                
                                                <h3>Parameters</h3>
                                                <Form.Item label="Sort Order (Smaller numbers appears at top)">
                                                    <Input 
                                                        className={Object.keys(updateObject)?.includes("sort_order") ? "sort-order-input updated-description" : "sort-order-input"}
                                                        value={(typeof updateObject?.sort_order !== "undefined" && !loading) ? updateObject?.sort_order : content?.sort_order}
                                                        onChange={(e) => {
                                                            handleFieldChange(
                                                                content?._id?.toString(), 
                                                                "sort_order", 
                                                                !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : ""
                                                            )
                                                        }}
                                                    />
                                                </Form.Item>

                                                <Upload
                                                    showUploadList={false}
                                                    onChange={async ({ file }) => {
                                                        const getBase64 = (file) => {
                                                            return new Promise((resolve, reject) => {
                                                                const reader = new FileReader();
                                                                reader.readAsDataURL(file);
                                                                reader.onload = () => resolve(reader.result);
                                                                reader.onerror = (error) => reject(error);
                                                            });
                                                        }

                                                        const preview = await getBase64(file.originFileObj);
                                                        let fileObject = file.originFileObj;
                                                        fileObject.preview = preview;
                                                        handleFieldChange(content?._id?.toString(), "carousel_image", fileObject)
                                                    }}
                                                >
                                                    <Button>Upload New Image</Button>
                                                </Upload>
                                            </div>
                                        </div>

                                        <div style={{ padding: "1rem" }}>
                                            <Collapse>
                                                <Panel header="Description Translations" key="1">
                                                    <div className="descriptions">
                                                        { descriptionLanguages?.map((language, index) => (
                                                            <Form.Item 
                                                                className="description"
                                                                label={`Description (${language})`} 
                                                                key={index}
                                                            >
                                                                <TextArea 
                                                                    value={(typeof updateObject?.[`seo_abouts.${language}`] !== "undefined" && !loading) ? updateObject?.[`seo_abouts.${language}`] : content?.seo_abouts?.[language]}
                                                                    autoSize
                                                                    className={Object.keys(updateObject)?.includes(`seo_abouts.${language}`) ? "updated-description" : ""}
                                                                    onChange={(e) => {
                                                                        handleFieldChange(content?._id?.toString(), `seo_abouts.${language}`, e.target.value)
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        )) }
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                    </div>
                                </Form>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
};

export default EditHomePageTopVendors;