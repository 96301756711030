import './OrderTrackSearchBar.css';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderFilter, setLogisticsOrderFilter} from '../../redux/filterSlice';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { ADMIN_ACTIVE_ORDER_STATUSES } from '../../constants';

function OrderTrackSearchBar({type, fieldName, placeholder}) {

  const dispatch = useDispatch();

  const { orderFilter : filter } = useSelector((state) => state.filter);
  
  const [localText, setLocalText] = useState('');

  const setFilterOrder = useCallback((value) => {
    if (type === "order") {
      dispatch(setOrderFilter(value));
    } else if (type === "logistics-order") {
      dispatch(setLogisticsOrderFilter(value));
    }
  }, [dispatch]);

  const debouncedFetchResults = useCallback(
    debounce((filter) => {
      setFilterOrder(filter);
    }, 200),
    [dispatch]
  );

  return (
    <div className="order-track-search-wrapper">
      <div className="order-track-search-input">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            let updatedFilter = JSON.parse(JSON.stringify(filter));
            updatedFilter.filter[fieldName] = localText;
            setFilterOrder(updatedFilter);
          }}
        >
          <div className="order-track-search-area">
            <i className="bi bi-search search-icon"></i>
            <input
              type="text"
              placeholder={placeholder}
              value={localText}
              onChange={(e) => {
                setLocalText(e.target.value);
                let updatedFilter = JSON.parse(JSON.stringify(filter));
                updatedFilter.filter = {};
                if(e.target.value === ""){
                  updatedFilter.filter["status"] = ADMIN_ACTIVE_ORDER_STATUSES;
                } 
                updatedFilter.filter[fieldName] = e.target.value;
                debouncedFetchResults(updatedFilter);
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default OrderTrackSearchBar;
