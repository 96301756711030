import React, { useState } from "react";
import { Form, Input, Select, Button, notification } from 'antd';
import { useSelector } from "react-redux";
import _ from 'underscore';
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { fetchVendorProducts } from "../../redux/vendorSlice"

import { getBase64, separateAndCapitalize } from "../../helpers";

import { ImageBoxForVendorBanner, ResponsiveImage } from "../../components";

import "./EditVendor.scss";
import { useNavigate } from "react-router-dom";

const EditVendorInfo = ({
    updatedVendor,
    setUpdatedVendor,
    initialVendor,
    setInitialVendor,
    isNew,
    setFiles,
    sourceLanguage,
    setSourceLanguage,
    form,
    allwaysManualLanguages,
    bannerFileList,
    setBannerFileList
}) => {

    const navigate = useNavigate();
    const { allProductTypes: productTypes } = useSelector((state) => state.productTypes);
    
    const [newProductTypeSizeChart, setNewProductTypeSizeChart] = useState();
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const [vendorProducts, setVendorProducts] = useState(initialVendor?.products?.map((product) => product?.product_code) || []);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
    
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const createHandle = (name) => {
        const dashArr = name?.split(" ");
        if(dashArr?.length) {
            return dashArr?.map((x) => x.toLowerCase()).join("_")?.trimStart();
        }
        return "";
    } 

    const createVendorTexts = (temp) => {
        setUpdatedVendor({
            ...updatedVendor, 
            name : temp,
            handle : createHandle(temp),
            "titles.en": `${temp} Wholesale Women’s Clothing - Lonca`,
            "titles.tr": `${temp} Toptan Kadın Giyim - Lonca`,
            "descriptions.en": `Shop ${temp} wholesale dresses, crop tops, jeans, leggings, and many more at Lonca. No Minimum!`,
            "descriptions.tr": `${temp} toptan kadın giyim koleksiyonundan toptan elbiseler, crop toplar, kot pantolonlar, taytlar ve daha fazlası Lonca'da. Minimum Sepet Tutarı Yok!`
        })

        setInitialVendor({
            ...initialVendor, 
            name : temp,
            handle : createHandle(temp),
            titles : {
                ...initialVendor?.titles,
                en : `${temp} Wholesale Women’s Clothing - Lonca`,
                tr : `${temp} Toptan Kadın Giyim - Lonca`
            },
            descriptions : {
                ...initialVendor?.descriptions,
                en : `Shop ${temp} wholesale dresses, crop tops, jeans, leggings, and many more at Lonca. No Minimum!`,
                tr : `${temp} toptan kadın giyim koleksiyonundan toptan elbiseler, crop toplar, kot pantolonlar, taytlar ve daha fazlası Lonca'da. Minimum Sepet Tutarı Yok!`
            },
        })
    }

    const setVendorInfo = ({parentField, field, value}) => {
        if (parentField) {
            setUpdatedVendor({
                ...updatedVendor,
                [`${parentField}.${field}`]: value
            })
            setInitialVendor({
                ...initialVendor,
                [parentField]: {
                    ...initialVendor[parentField],
                    [field]: value
                }
            })
        } else {
            setUpdatedVendor({
                ...updatedVendor,
                [field]: value
            })
            setInitialVendor({
                ...initialVendor,
                [field]: value
            })
        }
    }

    const uploadButton = (name) => (
        <label className="custom-file-upload">
            <input type="file" onChange={(e) => {
                setFiles(files => [...files?.filter(f => f?.type !== name), { file: e.target.files[0], type: name }]);
                
                let updatedVendorCopy = { ...updatedVendor };
                updatedVendorCopy[name.toLocaleLowerCase()] = URL.createObjectURL(e.target.files[0]);
                setUpdatedVendor(updatedVendorCopy)

                let initialVendorCopy = { ...initialVendor };
                initialVendorCopy[name.toLocaleLowerCase()] = URL.createObjectURL(e.target.files[0]);
                setInitialVendor(initialVendorCopy)
            }}/>
            <UploadOutlined />
            {'  '}{(`Upload ${name}`)}
        </label>
    )

    const uploadProductTypeSizeChartButton = (name) => (
        <label className="custom-file-upload">
            <input type="file" onChange={(e) => {
                setFiles(files => [...files?.filter(f => f?.type !== name), { file: e.target.files[0], type: name, productTypeSizeChart: true }]);

                let updatedVendorCopy = JSON.parse(JSON.stringify(updatedVendor));
                if (!updatedVendorCopy?.product_type_size_charts) updatedVendorCopy.product_type_size_charts = { ...initialVendor?.product_type_size_charts } || {};
                updatedVendorCopy.product_type_size_charts[name.toLocaleLowerCase()] = URL.createObjectURL(e.target.files[0]);
                setUpdatedVendor(updatedVendorCopy)

                let initialVendorCopy = JSON.parse(JSON.stringify(initialVendor));
                if (!initialVendorCopy?.product_type_size_charts) initialVendorCopy.product_type_size_charts = {};
                initialVendorCopy.product_type_size_charts[name.toLocaleLowerCase()] = URL.createObjectURL(e.target.files[0]);
                setInitialVendor(initialVendorCopy)
            }}/>
            <UploadOutlined />
            {'  '}{(`Upload ${name} Size Chart`)}
        </label>
    )

    const saveVendorProducts = async () => {
        await fetchVendorProducts(initialVendor?._id, vendorProducts)
            .then(async response => {
                notification['success']({
                    message: "Vendor Photos added successfully"
                });

                setTimeout(() => {
                    navigate('/vendors')
                  }, 2000);
            })
            .catch((err) => {
                notification['error']({
                    message: err?.response?.data?.error
                })
                .catch((err) => {
                    notification['error']({
                        message: "Vendor Photos couldn't added successfully"
                    });
                })
            })
    }

    return (
        <div className="form-top-vendor">
            <div className="inline-three-inputs-vendor">
                <Form.Item label={(`Name`)} name="name">
                    <Input
                        required
                        value={initialVendor?.name}
                        onChange={(e) => {
                            createVendorTexts(e.target.value)
                        }}>
                    </Input>
                </Form.Item>
                <Form.Item label={(`Vendor Initials`)} name="vendor_initial">
                    <Input
                        required
                        disabled={!isNew}
                        value={initialVendor?.vendor_initial}
                        onChange={(e) => {
                            setVendorInfo({field: "vendor_initial", value: e.target.value});
                        }}>
                    </Input>
                </Form.Item>

                <Form.Item label={(`Handle`)}  name="handle">
                    <Input
                        disabled={true}
                        value={initialVendor?.handle}
                    >
                    </Input>
                </Form.Item>
            </div>
            <div className="inline-three-inputs-vendor">
                <Form.Item label={(`Instagram`)} name="instagram">
                    <Input
                        placeholder="Optional"
                        value={initialVendor?.instagram}
                        onChange={(e) => {
                            setVendorInfo({field: "instagram", value: e.target.value});
                        }}>
                    </Input>
                </Form.Item>
                <Form.Item label={(`Location`)}  name="location">
                    <Input
                        placeholder="Optional"
                        value={initialVendor?.location}
                        onChange={(e) => {
                            setVendorInfo({field: "location", value: e.target.value});
                        }}>
                    </Input>
                </Form.Item>
                <Form.Item label={(`Founding Year`)}  name="founding_year">
                <Input
                        placeholder="Optional"
                        value={initialVendor?.founding_year}
                        onChange={(e) => {
                            setVendorInfo({field: "founding_year", value: e.target.value});
                        }}/>
                </Form.Item>
            </div>

            <div className="inline-two-inputs-vendor-title vendor-border">
                <Form.Item label={(`Title`)}  name="title_gb">
                    <TextArea
                        placeholder={`${"Vendor Name"} Wholesale Women’s Clothing - Lonca`}
                        value={initialVendor?.titles?.en}
                        required
                        rows={2}
                        onChange={(e) => {
                            setSourceLanguage('en')
                            setUpdatedVendor({
                                ...updatedVendor,
                                "titles.en": e.target.value,
                                "titles.tr": form.getFieldValue('title_tr') || initialVendor?.titles?.tr
                            })
                        }}
                    />
                </Form.Item>

                <Form.Item label={(`Title (Turkish)`)}  name="title_tr">
                    <TextArea
                        placeholder={`"Vendor Name" Toptan Kadın Giyim - Lonca`}
                        value={initialVendor?.titles?.tr}
                        required
                        rows={2}
                        onChange={(e) => {
                            setSourceLanguage('tr')
                            setUpdatedVendor({
                                ...updatedVendor,
                                "titles.tr": e.target.value,
                            })
                        }}
                    />
                </Form.Item>
            </div>
            <div className="inline-two-inputs-vendor">
                <Form.Item label={(`Description`)}  name="description_gb">
                    <TextArea
                        placeholder= {`Shop ${"Vendor Name"} wholesale dresses, crop tops, jeans, leggings, and many more at Lonca. No Minimum!`}
                        value={initialVendor?.descriptions?.en}
                        required
                        rows={4}
                        onChange={(e) => {
                            setSourceLanguage('en')
                            setUpdatedVendor({
                                ...updatedVendor,
                                "descriptions.en": e.target.value,
                                "descriptions.tr": form.getFieldValue('description_tr') || initialVendor?.descriptions?.tr
                            })
                        }}
                    />
                </Form.Item>
                    <Form.Item label={(`Description (Turkish)`)}  name="description_tr">
                    <TextArea
                        placeholder={`${"Vendor Name"} toptan kadın giyim koleksiyonundan toptan elbiseler, crop toplar, kot pantolonlar, taytlar ve daha fazlası Lonca'da. Minimum Sepet Tutarı Yok!`}
                        value={initialVendor?.descriptions?.tr}
                        required
                        rows={4}
                        onChange={(e) => {
                            setSourceLanguage('tr')
                            setUpdatedVendor({
                                ...updatedVendor,
                                "descriptions.tr": e.target.value,
                            })
                        }}
                    />
                </Form.Item>
            </div>

            <Form.Item label={(`About Vendor`)} name="about_en">
                <TextArea
                    value={initialVendor?.abouts?.en}
                    placeholder="Optional"
                    rows={5}
                    onChange={(e) => {
                        setSourceLanguage('en')
                        setUpdatedVendor({
                            ...updatedVendor,
                            translation_config: {
                                ...initialVendor?.translation_config,
                                abouts: {
                                    ...initialVendor?.translation_config?.abouts,
                                    manual_translation_languages: {
                                        ...initialVendor?.translation_config?.abouts?.manual_translation_languages,
                                        ...updatedVendor?.translation_config?.abouts?.manual_translation_languages,
                                        ...Object.fromEntries(allwaysManualLanguages.map(language => [language, true]))
                                    }
                                }
                            },
                            "abouts.en": e.target.value,
                            "abouts.tr": form?.getFieldValue('about_tr') ||  initialVendor?.abouts?.tr || "",
                        })
                    }}
                />
            </Form.Item>

            <div className="about-translation-container">

                <Form.Item label={(`About Vendor (Turkish)`)} name="about_tr">
                    <TextArea
                        value={initialVendor?.abouts?.tr}
                        placeholder="Optional"
                        rows={5}
                        onChange={(e) => {
                            setSourceLanguage('tr');
                            setUpdatedVendor({
                                ...updatedVendor,
                                translation_config: {
                                    ...initialVendor?.translation_config,
                                    abouts: {
                                        ...initialVendor?.translation_config?.abouts,
                                        manual_translation_languages: {
                                            ...initialVendor?.translation_config?.abouts?.manual_translation_languages,
                                            ...updatedVendor?.translation_config?.abouts?.manual_translation_languages,
                                            ...Object.fromEntries(allwaysManualLanguages.map(language => [language, true]))
                                        }
                                    }
                                },
                                "abouts.tr": e.target.value,
                            });
                        }}
                    />
                </Form.Item>
            </div>

            <div className="inline-two-inputs-vendor">
                <Form.Item label={(`Top Vendor SEO Description`)}  name="seo_description_gb">
                    <TextArea
                        value={initialVendor?.seo_abouts?.en}
                        rows={4}
                        onChange={(e) => {
                            setSourceLanguage('en')
                            setUpdatedVendor({
                                ...updatedVendor,
                                translation_config: {
                                    ...initialVendor?.translation_config,
                                    seo_abouts: {
                                        ...initialVendor?.translation_config?.seo_abouts,
                                        manual_translation_languages: {
                                            ...initialVendor?.translation_config?.seo_abouts?.manual_translation_languages,
                                            ...updatedVendor?.translation_config?.seo_abouts?.manual_translation_languages,
                                            ...Object.fromEntries(allwaysManualLanguages.map(language => [language, true]))
                                        }
                                    }
                                },
                                "seo_abouts.en": e.target.value,
                                "seo_abouts.tr": initialVendor?.seo_abouts?.tr
                            })
                        }}
                    />
                </Form.Item>

                <Form.Item label={(`Top Vendor SEO Description (Turkish)`)}  name="seo_description_tr">
                    <TextArea
                        value={initialVendor?.seo_abouts?.tr}
                        rows={4}
                        onChange={(e) => {
                            setSourceLanguage('tr')
                            setUpdatedVendor({
                                ...updatedVendor,
                                translation_config: {
                                    ...initialVendor?.translation_config,
                                    seo_abouts: {
                                        ...initialVendor?.translation_config?.seo_abouts,
                                        manual_translation_languages: {
                                            ...initialVendor?.translation_config?.seo_abouts?.manual_translation_languages,
                                            ...updatedVendor?.translation_config?.seo_abouts?.manual_translation_languages,
                                            ...Object.fromEntries(allwaysManualLanguages.map(language => [language, true]))
                                        }
                                    }
                                },
                                "seo_abouts.tr": e.target.value,
                            })
                        }}
                    />
                </Form.Item>
            </div>
            
            <div className="image-container">
                <div className="image-div">
                    {uploadButton("Logo")}
                    <div className="image">
                        {
                            initialVendor?.logo?.includes("aws") ?
                            <ResponsiveImage width={312} alt="logo" src={initialVendor?.logo} className="image"/> :
                            <img width={312} alt="logo" src={initialVendor?.logo}/>
                        }
                    </div>
                </div>

                {/* <div className="image-div">
                    {uploadButton("Banner")}
                    <div className="image">
                        {
                            initialVendor?.banner?.includes("aws") ?
                            <ResponsiveImage width={312} alt="logo" src={initialVendor?.banner} className="image"/> :
                            <img width={312} alt="logo" src={initialVendor?.banner}/>
                        }
                    </div>
                </div> */}

                <div className="image-div">
                    {uploadButton("Size_Chart")}
                    <div className="image">
                        {
                            initialVendor?.size_chart?.includes("aws") ?
                            <ResponsiveImage width={312} alt="logo" src={initialVendor?.size_chart} className="image"/> :
                            <img width={312} alt="logo" src={initialVendor?.size_chart}/>
                        }
                    </div>
                </div>

                <div className="image-div">
                    {uploadButton("Carousel_Image")}
                    <div className="image">
                        {
                            initialVendor?.carousel_image?.includes("aws") ?
                            <ResponsiveImage width={200} alt="logo" src={initialVendor?.carousel_image} className="image"/> :
                            <img width={200} alt="logo" src={initialVendor?.carousel_image}/>
                        }
                    </div>
                </div>
            </div>

            <div className="vendor-image-box-container">
                <ImageBoxForVendorBanner 
                    fileList={bannerFileList}
                    setFileList={setBannerFileList}
                    handlePreview={handlePreview}
                    previewVisible={previewVisible}
                    previewTitle={previewTitle}
                    previewImage={previewImage}
                    setPreviewVisible={setPreviewVisible}
                />
            </div>

                <div className="vendor-image-box-container">
                    
                    <p>Add Product Codes for Vendors Page</p>

                    { initialVendor?.products?.length === 3 &&
                        <div className="inline-three-inputs-vendor">
                            {initialVendor?.products?.map((product) => {
                                return(
                                    <div className="image">
                                        {product?.main_image?.includes("aws") ?
                                            <ResponsiveImage width={200} alt="logo" src={product?.main_image} className="image"/> :
                                            <img width={200} alt="logo" src={product?.main_image}/>
                                        }
                                    </div>
                                ) })
                            }
                        </div>
                    }
                    <div className="inline-four-inputs-vendor"> 
                            <Form.Item name="product_code_0">
                                <div></div>
                                <Input
                                    defaultValue={initialVendor?.products?.[0]?.product_code}
                                    onChange={(e) => {
                                        const updatedProducts = [...vendorProducts];
                                        updatedProducts[0] = e.target.value;
                                        setVendorProducts(updatedProducts);
                                    }}
                                >
                                </Input>
                            </Form.Item>
                            <Form.Item name="product_code_1">
                                <div></div>
                                <Input
                                    defaultValue={initialVendor?.products?.[1]?.product_code}
                                    onChange={(e) => {
                                        const updatedProducts = [...vendorProducts];
                                        updatedProducts[1] = e.target.value;
                                        setVendorProducts(updatedProducts);
                                    }}>
                                </Input>
                            </Form.Item>
                            <Form.Item name="product_code_2">
                                <div></div>
                                <Input
                                    defaultValue={initialVendor?.products?.[2]?.product_code}
                                    onChange={(e) => {
                                        const updatedProducts = [...vendorProducts];
                                        updatedProducts[2] = e.target.value;
                                        setVendorProducts(updatedProducts);
                                    }}>
                                </Input>
                            </Form.Item>
                            <Button 
                                type="primary" 
                                onClick={() => {
                                    saveVendorProducts()
                                }} 
                                style={{ marginRight: "10px" }} >
                                {(`Save`)}
                            </Button>
                        </div>
                </div>

            <div className="product-type-size-chart-images">
                <h4 className="product-type-size-chart-images-header">Size Charts by Product Type</h4>

                <div className="product-type-size-chart-add-new">
                    <span>Add New Size Chart:</span>
                    <Form.Item name="product_type">
                        <Select
                            onChange={(e) => {
                                setNewProductTypeSizeChart(e);
                            }}
                            showSearch
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                        >
                            <Option value="all">All Types</Option>
                            
                            {productTypes?.slice().sort((a, b) => a.names?.en?.localeCompare(b.names?.en)).map((type, index) => (
                                <Option key={'vendor-select' + index} value={type?.handle}>
                                    {type?.names?.en}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {newProductTypeSizeChart && uploadProductTypeSizeChartButton(separateAndCapitalize(newProductTypeSizeChart))}

                </div>
                <div className="product-type-size-chart-images-container">
                    { Object.keys(initialVendor?.product_type_size_charts || {})?.map(product_type => (
                        <div className="product-type-size-chart-image-container">

                            <div 
                                className="product-type-size-chart-image-trash"
                                onClick={() => {
                                    setFiles(files => files?.filter(f => f?.type !== separateAndCapitalize(product_type)));

                                    let newInitialVendorSizeCharts = initialVendor?.product_type_size_charts;
                                    newInitialVendorSizeCharts = _.omit(newInitialVendorSizeCharts, product_type?.toLowerCase());
                                    setInitialVendor(v => ({ ...v, product_type_size_charts: newInitialVendorSizeCharts }));

                                    let newUpdatedVendorSizeCharts = (updatedVendor?.product_type_size_charts || {});
                                    newUpdatedVendorSizeCharts = _.omit(newUpdatedVendorSizeCharts, product_type?.toLowerCase());
                                    setUpdatedVendor(v => ({ ...v, product_type_size_charts: newUpdatedVendorSizeCharts }));
                                }}
                            >
                                <DeleteOutlined />
                            </div>

                            <span>{separateAndCapitalize(product_type, "_")}</span>

                            <div className="product-type-size-chart-image">
                                {
                                    initialVendor?.product_type_size_charts[product_type]?.includes("aws") 
                                        ? <ResponsiveImage width={200} alt="logo" src={initialVendor?.product_type_size_charts[product_type]}/> 
                                        : <img width={200} alt="logo" src={initialVendor?.product_type_size_charts[product_type]}/>
                                }
                            </div>

                            {uploadProductTypeSizeChartButton(separateAndCapitalize(product_type))}
                        </div>
                    )) }
                </div>
            </div>
        </div>
    )
};

export default EditVendorInfo;