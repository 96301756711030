import { Link, Outlet, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { LanguageSelector, TestModeSwitch} from "../../components";
import { useTranslation } from "react-i18next";

import {
  SkinOutlined,
  ShopOutlined,
  GiftOutlined,
  UserOutlined,
  UserSwitchOutlined,
  ShoppingCartOutlined,
  DropboxOutlined,
  ShoppingOutlined,
  SlidersOutlined,
  PullRequestOutlined,
  FileDoneOutlined,
  TableOutlined,
  MailOutlined,
  PlusOutlined,
  CreditCardOutlined,
  LoginOutlined,
  FilterOutlined,
  FileSyncOutlined,
  PercentageOutlined,
  FieldTimeOutlined,
  FileTextOutlined,
  DesktopOutlined,
  CarOutlined,
  HeartOutlined,
  OneToOneOutlined,
  BranchesOutlined,
  SettingOutlined
} from "@ant-design/icons";

import { useDispatch } from "react-redux";

import "./MainLayout.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchVendors, fetchSubVendors } from "../../redux/vendorSlice";
import { fetchTags } from "../../redux/tagSlice";
import { fetchAllSeries } from "../../redux/seriesSlice";
import { fetchPaymentTypes } from "../../redux/paymentTypesSlice";
import { fetchAllProductTypes } from "../../redux/productTypesSlice";
import { fetchColors } from "../../redux/colorSlice";
import { fetchInputs } from "../../redux/registrationInputSlice";
import { fetchGenders } from "../../redux/genderSlice";
import { logOut } from "../../redux/userSlice";
import { resetLogisticsOrderFilter, resetOrderFilter, resetOrderStatusFilter } from "../../redux/filterSlice";
import { fetchRoles } from "../../redux/roleSlice";
import { fetchFilterTypes } from "../../redux/filterTypeSlice";
import { isRoleHavePermission } from "../../helpers";
import { resetRefundFilter } from "../../redux/refundSlice";
import { fetchAllExchangeRatesToState } from "../../redux/exchangeRatesSlice"
       
function MainLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');

  const { status: vendorStatus } = useSelector((state) => state.vendors);
  const { status: userStatus, user, languagePath } = useSelector((state) => state.user);
  const { status: tagStatus } = useSelector((state) => state.tags);
  const { status: seriesStatus } = useSelector((state) => state.series);
  const { status: paymentTypesStatus } = useSelector((state) => state.paymentTypes);
  const { status: productTypeStatus } = useSelector((state) => state.productTypes);
  const { status: colorStatus } = useSelector((state) => state.colors);
  const { status: genderStatus } = useSelector((state) => state.gender);
  const { status: registrationInputStatus } = useSelector((state) => state.registrationInput);
  const { status: roleStatus, roles } = useSelector((state) => state.role);
  const { status: filterTypeStatus } = useSelector((state) => state.filterType);
  const { status: exchangeRateStatus } = useSelector((state) => state.exchangeRates);
  const { environment } = useSelector((state) => state.user);

  useEffect(() => {
    if(userStatus.auth === "succeeded") {
      if (vendorStatus.fetchVendors === "idle") dispatch(fetchVendors());
      if (vendorStatus.fetchSubVendors === "idle") dispatch(fetchSubVendors());
      if (tagStatus.fetchTags === "idle") dispatch(fetchTags());
      if (seriesStatus.fetchAllSeries === "idle") dispatch(fetchAllSeries());
      if (paymentTypesStatus.fetchPaymentTypes === "idle") dispatch(fetchPaymentTypes());
      if (productTypeStatus.fetchAllProductTypes === "idle") dispatch(fetchAllProductTypes());
      if (colorStatus.fetchColors === "idle") dispatch(fetchColors());
      if (genderStatus.fetchGenders === "idle") dispatch(fetchGenders());
      if (registrationInputStatus.fetchInputs === "idle") dispatch(fetchInputs());
      if (exchangeRateStatus.fetchAllExchangeRatesToState === "idle") dispatch(fetchAllExchangeRatesToState());
      if (roleStatus === "idle") dispatch(fetchRoles());
      if (filterTypeStatus.fetchFilterTypes === "idle") dispatch(fetchFilterTypes());
    }
  }, [userStatus.auth]);

  const havePermission = (tab) => {
    if (!userStatus.auth === "succeeded") return false;

    const role = roles?.filter((role) => role?.name === user?.admin_role)[0]
    return role?.tabs?.includes(tab);
  }

  return (
    <div className="layout">
      <div className={`layout-nav ${environment === 'test' && "test-mode"}`}>
        <div className="logo">LONCA</div>
        {environment === 'test' && <div className="test-data-label">TEST DATA</div>}
        <div className="buttons-item">
          {isRoleHavePermission(['Admin', 'Vendor Manager', 'Account Management'], user?.admin_role) && <div><TestModeSwitch/></div>}
          <div><LanguageSelector /></div>
          <div className="logout"><Button onClick={() => {
            dispatch(logOut());
            navigate(`${languagePath}/login?path=${window.location.pathname}`)
          }}>{t('home.Log Out')}</Button></div>
        </div>

      </div>
      <div className="container main-layout">
        <div className="layout-aside">
          {havePermission("Products") && <Link to="/products">
            <Button icon={<SkinOutlined />}>Products</Button>
          </Link>}
          {havePermission("New Products") && <Link to="/new-products">
            <Button icon={<PlusOutlined />}>New Products</Button>
          </Link>}
          {havePermission("CSV Discounted Products") && <Link to="/csv-discounted-products">
            <Button icon={<PercentageOutlined />}>{ t("fields.discounted_products.title") }</Button>
          </Link>}
          {havePermission("Product Matching") && <Link to="/product-matching">
            <Button icon={<PullRequestOutlined />}>Product Matching</Button>
          </Link>}
          {havePermission("Vendors") && <Link to="/vendors">
            <Button icon={<ShopOutlined />}>Vendors</Button>
          </Link>}
          {havePermission("Tag Products") && <Link to="/tag-products">
            <Button icon={<FileSyncOutlined />}>Tag Products</Button>
          </Link>}
          {havePermission("Product Details") && <Link to="/product-details">
            <Button icon={<FileTextOutlined />}>Product Details</Button>
          </Link>}
          {havePermission("Static Contents") && <Link to="/static-contents">
            <Button icon={<DesktopOutlined />}>Static Contents</Button>
          </Link>}
          {havePermission("Marketing Flows") && <Link to="marketing-flows">
            <Button icon={<BranchesOutlined />}>Marketing Flows</Button>
          </Link>}
          {havePermission("Tags") && <Link to="/tags">
            <Button icon={<FileDoneOutlined />}>Tags</Button>
          </Link>}
          {havePermission("Filters") && <Link to="/filters">
            <Button icon={<FilterOutlined />}>Filters</Button>
          </Link>}
          {havePermission("Cargo Companies") && <Link to="/cargo-companies">
            <Button icon={<DropboxOutlined />}>Cargo Companies</Button>
          </Link>}
          {havePermission("Supplier Invoices") && <Link to="/supplier-applications">
            <Button icon={<ShopOutlined />}>Supplier Applications</Button>
          </Link>}
          {havePermission("Series") && <Link to="series">
            <Button icon={<SlidersOutlined />}>Series</Button>
          </Link>}
          {havePermission("Customers") && <Link to="/customers">
            <Button icon={<UserOutlined />}>Customers</Button>
          </Link>}
          {havePermission("Logistics Customers") && <Link to="/logistics-customers">
            <Button icon={<UserOutlined />}>Logistics Customers</Button>
          </Link>}
          {havePermission("Credit Applications") && <Link to="/credit-applications">
            <Button icon={<UserOutlined />}>Credit Applications</Button>
          </Link>}
          {havePermission("Credit Status") && <Link to="/credit-status">
            <Button icon={<CreditCardOutlined />}>Credit Status</Button>
          </Link>}
          {havePermission("Admin Roles") && <Link to="/roles">
            <Button icon={<UserSwitchOutlined />}>Admin Roles</Button>
          </Link>}
          {havePermission("Users") && <Link to="/users">
            <Button icon={<UserSwitchOutlined />}>Users</Button>
          </Link>}
          {havePermission("Carts") && <Link to="/carts">
            <Button icon={<ShoppingCartOutlined />}>Carts</Button>
          </Link>}
          {havePermission("Favorites") && <Link to="/favorites">
            <Button icon={<HeartOutlined />}>Favorites</Button>
          </Link>}
          {havePermission("Shelves") && <Link to="/shelves">
            <Button icon={<DropboxOutlined />}>Shelves</Button>
          </Link>}
          {havePermission("Orders") && 
            <Link to="/orders" onClick={() => {
              if (window.location.pathname === "/orders") {
                navigate('/products?123');
              }
              dispatch(resetOrderFilter())
            }}>
              <Button icon={<ShoppingOutlined />}>Orders</Button>
            </Link>
          }
          {havePermission("Logistics Orders") && 
            <Link to="/logistics-orders" onClick={() => {
              dispatch(resetOrderFilter())
              dispatch(resetLogisticsOrderFilter())
            }}>
              <Button icon={<ShoppingOutlined />}>Logistics Orders</Button>
            </Link>
          }
          {havePermission("Refunds") && <Link to="/refunds">
            <Button onClick={() => dispatch(resetRefundFilter())} icon={<LoginOutlined rotate={180} />}>Returns</Button>
          </Link>}
          {havePermission("Refund Products") && <Link to="/refund-products">
            <Button onClick={() => dispatch(resetRefundFilter())} icon={<LoginOutlined rotate={180} />}>Return Products</Button>
          </Link>}
          {havePermission("Order Products") && <Link to="/order-products">
            <Button icon={<MailOutlined />}>Order Products</Button>
          </Link>}
          {havePermission("Late Products") && <Link to="/late-products">
            <Button icon={<FieldTimeOutlined />}>Late Products</Button>
          </Link>}
          {havePermission("All Logistic Orders") && <Link to="/all-logistic-orders">
            <Button icon={<MailOutlined />}>All Logistic Orders</Button>
          </Link>}
          {havePermission("All Orders") && <Link to="/all-orders">
            <Button icon={<MailOutlined />}>All Orders</Button>
          </Link>}
          {havePermission("Order Status") && <Link to="/order-status">
            <Button onClick={() => dispatch(resetOrderStatusFilter())} icon={<TableOutlined />}>Order Status</Button>
          </Link>}
          {havePermission("First Mile Shipments") && <Link to="/first-mile-shipments">
            <Button icon={<CarOutlined />}>First Mile Shipments</Button>
          </Link>}
          {havePermission("Coupons") && <Link to="/coupons">

            <Button icon={<GiftOutlined />}>Coupons</Button>
          </Link>}
          {havePermission("Customer Invoices") && <Link to="/invoices">
            <Button icon={<FileDoneOutlined />}>Customer Invoices</Button>
          </Link>}
          {havePermission("Supplier Invoices") && <Link to="/supplier-invoices">
            <Button icon={<FileDoneOutlined />}>Supplier Invoices</Button>
          </Link>}
          {havePermission("Lonca Supplier Invoices") && <Link to="/lonca-invoices">
            <Button icon={<FileDoneOutlined />}>Lonca Supplier Invoices</Button>
          </Link>}

          {havePermission("Supplier Authority") && <Link to="/supplier-authority">
            <Button icon={<OneToOneOutlined />}>Supplier Authority</Button>
          </Link>}

          {havePermission("Configurations") && <Link to="/configurations">
            <Button icon={<SettingOutlined />}>Configurations</Button>
          </Link>}
        </div>
        <div className="layout-section">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
