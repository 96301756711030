const FREE_SHIPPING = 'FREE_SHIPPING';
const NOT_FREE_SHIPPING = 'NOT_FREE_SHIPPING';
const FIXED_SHIPPING = 'FIXED_SHIPPING';
const CREDIT_CARD_FEE = 3.5;
const VAT = 0.08;
const DESI_CONSTANT = 5000;

const ADMIN_VALID_ALL_ORDERS_ORDER_STATUSES = ['Received', 'Customer Pending', 'Confirmed', "Operations Pending", "Ready", "On the Road", 'Delivery', 'Completed', 'Lost & Undelivered', 'Returned', 'Cancelled'];
const ADMIN_ACTIVE_ORDER_STATUSES = ['Received', 'Customer Pending', 'Confirmed', "Operations Pending", "Packaged", "Ready", "On the Road", 'Delivery'];
const ADMIN_VALID_ALL_ORDERS_ITEM_STATUSES = ['Received', 'Confirmed', 'StockOut', 'StockIn', 'Wrong / Missing Items', 'Reviewed', 'Returned', 'Defected'];
const ADMIN_VALID_ORDER_PRODUCTS_ORDER_STATUSES = ['Received', 'Customer Pending', 'Confirmed', "Operations Pending", "Ready"];
const ADMIN_VALID_ORDER_PRODUCTS_ITEM_STATUSES = ['Received', 'Confirmed', 'StockOut', 'StockIn', 'Wrong / Missing Items', 'Reviewed'];
const ADMIN_VALID_LATE_PRODUCTS_ITEM_STATUSES = ['Received', 'Confirmed', 'Wrong / Missing Items'];
const ADMIN_DISABLED_ITEM_STATUSES = ["StockOut", "Returned"];
const ADMIN_DISABLED_ORDER_STATUSES = ['Customer Pending', 'Confirmed', "Operations Pending", "Ready", "On the Road", 'Delivery', 'Completed', 'Lost & Undelivered', 'Returned', 'Cancelled'];
const ADMIN_VALID_CUSTOMER_INVOICE_ORDER_STATUSES =  ['Completed', 'Delivery', 'Received']
const REGION_PRICE_COEFFICIENTS = {
  Turkey: 1,
  Europe: 1,
  Others: 1.15,
};

const LOCAL_VAT_RATE = 0.21;

const LANGUAGE_ROUTES = ['/gb', '/'];
const LANGUAGES = [
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'gb',
    name: 'English',
  },
];

const LANGUAGE_CODES = ["en", "ar", "fr", "el", "ro", "ru", "sr", "de", "bg", "lt", "tr", "es", "pt", "it", "sl", "pl", "nl", "sk", "cs", "hr"];

const EXCHANGE_BOXES_INVALID_ORDER_REFUND_STATUSES = ["Disapproved", "Completed"];

const REASONS_FOR_ITEM_COUNT_NOT_SERIES = ["Defected", "Missing Items", "Wrong Series"];

const ALLOW_AUTO_REFUND_STOCK_OUT_RATIO = 0.6;

const ALL_ORDER_STATUSES = [
  { label: "status.All Status", value: "all" },
  { label: "fields.order_status.Created", value: "Created" },
  { label: "fields.order_status.Payment Pending", value: "Payment Pending" },
  { label: "fields.order_status.Received", value: "Received" },
  { label: "fields.order_status.Customer Pending", value: "Customer Pending" },
  { label: "fields.order_status.Confirmed", value: "Confirmed" },
  { label: "fields.order_status.Operations Pending", value: "Operations Pending" },
  { label: "fields.order_status.Ready", value: "Ready" },
  { label: "fields.order_status.On the Road", value: "On the Road" },
  { label: "fields.order_status.Delivery", value: "Delivery" }, 
  { label: "fields.order_status.Completed", value: "Completed" }, 
  { label: "fields.order_status.Lost & Undelivered", value: "Lost & Undelivered" }, 
  { label: "fields.order_status.Returned", value: "Returned" }, 
  { label: "fields.order_status.Cancelled", value: "Cancelled" }, 
  { label: "fields.order_status.Not Approved", value: "Not Approved" },
]

const ALL_LOGISTICS_ORDER_STATUSES = [
  { label: "status.All Status", value: "all" },
  { label: "fields.order_status.Received", value: "Received" },
  { label: "fields.order_status.Operations Pending", value: "Operations Pending" },
  { label: "fields.order_status.Packaged", value: "Packaged" },
  { label: "fields.order_status.Ready", value: "Ready" },
  { label: "fields.order_status.On the Road", value: "On the Road" },
  { label: "fields.order_status.Delivery", value: "Delivery" }, 
  { label: "fields.order_status.Completed", value: "Completed" }, 
  { label: "fields.order_status.Lost & Undelivered", value: "Lost & Undelivered" }, 
]

const ORDER_STATUS_STATUSES = [
  { label: "status.All Status", value: "all" },
  { label: "fields.order_status.Received", value: "Received" },
  { label: "fields.order_status.Customer Pending", value: "Customer Pending" },
  { label: "fields.order_status.Confirmed", value: "Confirmed" },
  { label: "fields.order_status.Operations Pending", value: "Operations Pending" },
  { label: "fields.order_status.Ready", value: "Ready" },
  { label: "fields.order_status.On the Road", value: "On the Road" },
  { label: "fields.order_status.Delivery", value: "Delivery" }
]

const GROWTHBOOK_EXPERIMENT_VALUES = ["global-price-test", "register-test"];

const SENDPULSE_OPERATORS = [
  "Erkam Cirdi",
  "Cakir Emirhan",
  "Sevval Sonmez",
  "Berk Semih Oncel"
]

const NON_COUPON_REFUND_REASONS = [
  "Defected",
  "Missing Items",
  "Missing Pack",
  "Wrong Products",
  "Wrong Items"
];

module.exports = {
  FREE_SHIPPING,
  NOT_FREE_SHIPPING,
  FIXED_SHIPPING,
  CREDIT_CARD_FEE,
  VAT,
  REGION_PRICE_COEFFICIENTS,
  DESI_CONSTANT,
  LANGUAGE_ROUTES,
  LANGUAGES,
  ADMIN_VALID_ALL_ORDERS_ITEM_STATUSES,
  ADMIN_VALID_ALL_ORDERS_ORDER_STATUSES,
  ADMIN_VALID_ORDER_PRODUCTS_ORDER_STATUSES,
  ADMIN_VALID_ORDER_PRODUCTS_ITEM_STATUSES,
  ADMIN_VALID_LATE_PRODUCTS_ITEM_STATUSES,
  ADMIN_DISABLED_ITEM_STATUSES,
  ADMIN_DISABLED_ORDER_STATUSES,
  LOCAL_VAT_RATE,
  LANGUAGE_CODES,
  EXCHANGE_BOXES_INVALID_ORDER_REFUND_STATUSES,
  REASONS_FOR_ITEM_COUNT_NOT_SERIES,
  ALLOW_AUTO_REFUND_STOCK_OUT_RATIO,
  ALL_ORDER_STATUSES,
  GROWTHBOOK_EXPERIMENT_VALUES,
  ORDER_STATUS_STATUSES,
  SENDPULSE_OPERATORS,
  ADMIN_VALID_CUSTOMER_INVOICE_ORDER_STATUSES,
  ADMIN_ACTIVE_ORDER_STATUSES,
  NON_COUPON_REFUND_REASONS,
  ALL_LOGISTICS_ORDER_STATUSES,
};
